import React, { useEffect } from "react";
import Sidebar from "../Siderbar/Sidebar";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import auth from '../service/auth';
import Login from './login_page';

const Connect = () => {
    const navigate = useNavigate();

    useEffect(() => {
        let Connectiondata = auth.isConnection();
        if (!Connectiondata) {
        } else {
            navigate("/dashboard");
        }
    }, [])

    return (
        <div>
            <section>
                <nav>
                    <div className="menu_bar_btn">
                        <span className="material-symbols-outlined" id="menu_btn">
                            menu
                        </span>
                    </div>
                </nav>
            </section>
            <section>
                <div className="PRODUCT_PAGE_SECTION">
                    <div className="row">
                        <Sidebar />
                        <div className="col-lg-12 col-xxl-10 col-xl-9">
                            <div className="Company_setting_inner">
                                <div className="right_side_content">
                                    <div className="COMPANY_INNER_SETTINGS">
                                        <Login />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </section>

            <script src="js/main.js"></script>

        </div>
    );
};

export default Connect;
