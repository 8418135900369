import React, { useState, useEffect } from 'react'
import Sidebar from "../Siderbar/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import auth from '../service/auth';
import axios from 'axios';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import Loader from './loader/Loader';
import Contact from '../service/contact';
import Modal from "react-modal";

const Order = () => {
    const navigate = useNavigate();

    const [invoices, setInvoices] = useState([]);
    const [searchFilter, setSearchFilter] = useState([]);
    const [item, setItem] = useState([])
    const [loader, setLoader] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageModal, setPageModal] = useState(0);
    const [rowsPerPageModal, setRowsPerPageModal] = useState(10);

    const handleChangePageModal = (event, newPage) => setPageModal(newPage);
    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeRowsPerPageModal = (event) => {
        setRowsPerPageModal(+event.target.value);
        setPageModal(0);
    };

    const openModal = () => {
        setIsModalOpen(true);
    }
    const closeModal = () => {
        setIsModalOpen(false);
    }

    async function getInvoiceList() {
        try {
            setLoader(true)
            let user = auth.getAuth();
            if (user == null) {
                setLoader(false)
                return
            }
            var headres_ = {
                headers: {
                    'authorization': user.data.access_token,
                    locationapikeytoken: user.data.data.apiKey
                }
            }
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/invoices/customer`, headres_);
            setInvoices(response.data.data);
            setSearchFilter(response.data.data);
            setLoader(false)
        } catch (error) {
            setLoader(false)
            if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
                auth.disconnectUser()
            }
            console.log('error', error);
        }
    }

    async function filter(event) {
        if (event == "") {
            setSearchFilter(invoices);
            return
        }
        if (invoices && invoices.length != 0) {
            var result = invoices.filter((e) => String(e.invoice_number).includes(event));
            setSearchFilter(result);
        }
    }

    async function exportData() {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const ws = XLSX.utils.json_to_sheet(invoices);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Order" + fileExtension);
    }

    const handleAction = (data) => {
        try {
            openModal();
            setItem(data)
        } catch (error) {
        }
    }

    useEffect(() => {
        getInvoiceList()
    }, [])

    return (
        <div>
            <section>
                <div className="PRODUCT_PAGE_SECTION">
                    <div className="row">
                        <Sidebar />

                        <div className="col-lg-12 col-xxl-10 col-xl-9">
                            <div className="right_side_content">

                                <div className="store_data_table">
                                    <div className="row">
                                        <div className="main-title">

                                            <h2>Orders</h2>
                                            <button className="Export" onClick={exportData}> Export Orders </button>

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="main-title">
                                            <div className="searchfilter">
                                                <label className="searchLabel">  {" "} Search:   </label>
                                                <input
                                                    type="search"
                                                    className="form-control form-control-sm"
                                                    placeholder="Enter Order Number"
                                                    aria-controls="example"
                                                    onChange={e => filter(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Modal
                                        isOpen={isModalOpen}
                                        onRequestClose={closeModal}
                                        contentLabel="Example Modal"
                                        className="custom-modal"
                                        overlayClassName="custom-modal-overlay"
                                    >
                                        <div className='customer-card'>
                                            <div className='cus_inner_card_body'>
                                                <div className='close-modal'>
                                                    <div className='close__'>
                                                        <span class="material-symbols-outlined" onClick={closeModal}>
                                                            cancel
                                                        </span>
                                                    </div>
                                                </div>

                                                <TableContainer >
                                                    <Table id="invoiceTable" className="table table-striped table-bordered " style={{ width: '100%' }}>
                                                        <TableHead className="tableHeader">
                                                            <TableRow>
                                                                <TableCell>Item Name</TableCell>
                                                                <TableCell>Description</TableCell>
                                                                <TableCell>Unit Price</TableCell>
                                                                <TableCell>Quantity</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {item && item.length != 0 ? item?.slice(pageModal * rowsPerPageModal, pageModal * rowsPerPageModal + rowsPerPageModal)
                                                                ?.map((itemDetail, index) => {
                                                                    return (
                                                                        <TableRow key={itemDetail._id}>
                                                                            <TableCell>{itemDetail.product.name}</TableCell>
                                                                            <TableCell>{itemDetail.description}</TableCell>
                                                                            <TableCell>{itemDetail.unit_price}</TableCell>
                                                                            <TableCell>{itemDetail.qty}</TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })
                                                                : (item.length == 0 ? <></> : <><Loader /></>)
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10]}
                                                    component="div"
                                                    count={item.length}
                                                    rowsPerPage={rowsPerPageModal}
                                                    page={pageModal}
                                                    onPageChange={handleChangePageModal}
                                                    onRowsPerPageChange={handleChangeRowsPerPageModal}
                                                />
                                            </div>
                                        </div>
                                    </Modal>
                                    <TableContainer >
                                        <Table id="example" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                            <TableHead className="tableHeader">
                                                <TableRow>
                                                    <TableCell>Order Number</TableCell>
                                                    <TableCell>Amount</TableCell>
                                                    <TableCell>Item</TableCell>
                                                    <TableCell>Contact</TableCell>
                                                    <TableCell>Date and Time</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {!loader && invoices && invoices.length != 0 ? searchFilter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    ?.map((invoice, index) => {
                                                        return (
                                                            <TableRow key={invoice.id}>
                                                                <TableCell>{invoice.invoice_number}</TableCell>
                                                                <TableCell>{"$" + (String(invoice.amount).includes(".") ? (Math.round(invoice.amount * 100) / 100).toFixed(2) : (invoice.amount == null ? 0 : invoice.amount) + ".00")}</TableCell>
                                                                <TableCell>
                                                                    <div className="action-container">
                                                                        <button className="view-button" onClick={(e) => handleAction(invoice.line_items)}>View</button>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>{Contact.showContactList(invoice.user)}</TableCell>
                                                                <TableCell>{invoice.createdAt != null ? moment(invoice.createdAt).format('MM/DD/YYYY hh:mm A') : ''}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                    : (invoices.length == 0 ? <></> : <><Loader /></>)
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={invoices.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Order
