import React, { useState, useEffect } from 'react';
import Sidebar from "../Siderbar/Sidebar";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate, useLocation } from "react-router-dom";
import auth from '../service/auth';
import axios from 'axios';
import Loader from './loader/Loader';

const Create_Product = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [productList, setProductList] = useState([]);
    const [sku, setSKU] = useState("");
    const [name, setName] = useState("");
    const [uniCost, setUnitCost] = useState("");
    const [proAction, setProAction] = useState("add");
    const [oneTimeSetupFeeProductCost, setOneTimeSetupFeeProductCost] = useState("");
    const [tax, setTax] = useState(false);
    const [description, setDescription] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (location.state) {
            setProAction(location.state.type);
            setName(location.state.product.name);
            setSKU(location.state.product.sku);
            setUnitCost(location.state.product.unit_price);
            setTax(location.state.product.taxable ? true : false)
            setDescription(location.state.product.description);
            setOneTimeSetupFeeProductCost(location.state.product.one_time_setup_fee_product_cost);
        }
    }, [])

    const handleInputChange = (e, inputName) => {
        const value = e.target.value;

        switch (inputName) {
            case 'oneTimeSetupFeeProductCost':
                setOneTimeSetupFeeProductCost(value);
                break;
            case 'unitCost':
                setUnitCost(value);
                break;
            case 'name':
                setName(value);
                break;
            case 'sku':
                setSKU(value);
                break;
            // case 'type':
            //     setType(value);
            //     break;
            default:
                break;
        }
    };

    async function submit() {
        try {
            setLoader(true)
            if (!name || !uniCost) {
                return
            }
            let user = auth.getAuth();
            if (user == null) {
                setLoader(false)
                return
            }
            const storedXAccessToken = localStorage.getItem('accessToken');
            const retrieveXAccessToken = JSON.parse(storedXAccessToken);
            console.log(retrieveXAccessToken,"retrieveXAccessToken");


            var headres_ = {
                headers: {
                    'authorization': user.data.access_token,
                    'x-access-token': retrieveXAccessToken.accessToken
                }
            }
            var payload = {
                site_id: retrieveXAccessToken.SiteId,
                name: name,
                description: description,
                unit_price: (uniCost ? Number(uniCost) : 0),
                sku: sku,
                active: true,
                oneTimeSetupFeeProductCost: oneTimeSetupFeeProductCost,
                sku: sku
            };
            const productData = {
                external_id: Math.floor(100000000 + Math.random() * 900000000),
                site_id: retrieveXAccessToken.SiteId,
                name: name,
                unit_price: (uniCost ? Number(uniCost) : 0),
                active: true,
                xAccessToken: retrieveXAccessToken.accessToken,
            };
            const backendData = {
                external_id: productData.external_id,
                name: productData.name,
                unit_price: productData.unit_price,
                active: true,
                oneTimeSetupFeeProductCost: oneTimeSetupFeeProductCost,
                sku: sku,
                description: description,
            }
           
            // const response = await axios.post(`https://stage-api.360fivecheckout.io/api/v2/site/${productData.site_id}/product/addUpdate`, productData, {
            //     headers: {
            //         'x-access-token': productData.xAccessToken,
            //         'Content-Type': 'application/json'
            //     }
            // });
            // console.log('API Response:', response.data);
            // // const response = await axios.post(`${process.env.REACT_APP_URL}/api/create/product`, payload, headres_);
            // if (response.data.success) {
                const response = await axios.post(`${process.env.REACT_APP_URL}/api/create/product`, payload,headres_);
                setLoader(false)
                if (response.data.status) {
                    navigate("/product/list_");
                }
            // }


        } catch (error) {
            setLoader(false)
            if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
                auth.disconnectUser()
            }
            console.log('create product error', error);
        }
    }

    async function update() {
        try {
            setLoader(true)
            if (!name || !uniCost) {
                return
            }
            let user = auth.getAuth();
            if (user == null) {
                setLoader(false)
                return
            }
            const storedXAccessToken = localStorage.getItem('accessToken');
            const retrieveXAccessToken = JSON.parse(storedXAccessToken);
            var headres_ = {
                headers: {
                    'authorization': user.data.access_token,
                    'x-access-token': retrieveXAccessToken.accessToken
                }
            }
            var payload = {
                site_id: retrieveXAccessToken.SiteId,
                name: name,
                description: description,
                unit_price: (uniCost ? Number(uniCost) : 0),
                taxable: tax,
                active: true,
                oneTimeSetupFeeProductCost: oneTimeSetupFeeProductCost,
                sku: sku,
                external_id: location.state.product.external_id
            };

            const response = await axios.put(`${process.env.REACT_APP_URL}/api/products`, payload, headres_);

            setLoader(false)
            if (response.data.status) {
                navigate("/product/list_");
            }

        } catch (error) {
            setLoader(false)
            if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
                auth.disconnectUser()
            }
            console.log('create product error', error);
        }
    }

    return (
        <div>
            <section>
                <nav>
                    <div className="menu_bar_btn">
                        <span className="material-symbols-outlined" id="menu_btn">
                            menu
                        </span>

                    </div>
                </nav>
            </section>
            <section>
                <div className="PRODUCT_PAGE_SECTION Create_product_section">
                    <div className="row">
                        <Sidebar />
                        <div className="col-lg-12 col-xxl-10 col-xl-9">
                            <div className="right_side_content" style={{ position: 'relative' }}>
                                {loader ? <Loader /> : <></>}
                                <div className="store_data_table">
                                    <div className="row">
                                        <div className="main-title">
                                            <h2>Create Item</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="add_product_popup_page">
                                    <div className="inner_popup">

                                        <div className="popup_body">
                                            <div> <label  >Item Name <span>*</span></label>
                                                <input type="text" onChange={(e) => handleInputChange(e, 'name')} value={name} disabled={proAction == "edit" || proAction == "add" ? false : true} /></div>

                                            <div> <label  >Item SKU </label>
                                                <input type="text" onChange={(e) => handleInputChange(e, 'sku')} value={sku} disabled={proAction == "edit" || proAction == "add" ? false : true} />
                                            </div>

                                            <div className="Product_cost">
                                                <div>
                                                    <label  >Item Cost ($)<span>*</span></label>
                                                    <input type="text" onChange={(e) => handleInputChange(e, 'unitCost')} value={uniCost} disabled={proAction == "edit" || proAction == "add" ? false : true} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="radio_have">
                                            <input type="checkbox" onChange={(e) => setTax(!tax)} checked={tax} />
                                            <p>Taxable ? <span className='taxable_'>{"[ " + (tax ? "Yes" : "No") + " ]"}</span></p>
                                        </div>

                                        <div className="editer_sec">
                                            <label >Description </label>
                                            <Editor
                                                value={description}
                                                init={{
                                                    branding: false,
                                                    height: 400,
                                                    menubar: false,
                                                    plugins:
                                                        "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                                    toolbar:
                                                        "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                                    image_advtab: true
                                                }}
                                                onEditorChange={(newValue, editor) => {
                                                    setDescription(editor.getContent({ format: 'text' }));
                                                }}
                                                disabled={proAction == "edit" || proAction == "add" ? false : true}
                                            />
                                            {proAction == "add" ?
                                                <>
                                                    <button type="button" id="Save_changes" onClick={submit} style={auth.isConnection() == true ? { display: "block" } : { display: "none" }}>
                                                        <span className="material-symbols-outlined"> save </span>
                                                        Save Changes
                                                    </button>
                                                </>
                                                :
                                                proAction == "edit" ?
                                                    <>
                                                        <button type="button" id="Save_changes" onClick={update}>
                                                            Update
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Create_Product
