import React, { useState, useEffect } from 'react';
import Sidebar from "../Siderbar/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import auth from '../service/auth';
import axios from 'axios';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import printJS from "print-js";
import Loader from './loader/Loader';
import html2pdf from 'html2pdf.js';
import Contact from '../service/contact';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import Modal from "react-modal";


const Invoice_List = () => {
    const navigate = useNavigate();

    const [invoices, setInvoices] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [cancelData, setCancelData] = useState();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => setPage(newPage);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);



    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    async function getInvoiceList() {
        try {
            setLoader(true)
            let user = auth.getAuth();
            if (user == null) {
                setLoader(false)
                return
            }
            var headres_ = {
                headers: {
                    'authorization': user.data.access_token,
                    locationapikeytoken: user.data.data.apiKey
                }
            }
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/invoices/customer`, headres_);
            var sentInvoices = response.data.data.filter((inv, ind) => { return inv.status == "sent" });
            setInvoices(response.data.data);
            setFilterData(response.data.data);
        } catch (error) {
            setLoader(false)
            if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
                auth.disconnectUser()
            }
            console.log('error', error);
        }
    }

    async function getCustomers() {
        try {
            setLoader(true)
            let user = auth.getAuth();
            if (user == null) {
                setLoader(false)
                return
            }
            var headres_ = {
                headers: {
                    'authorization': user.data.access_token,
                    locationAPIKeyToken: user.data.data.apiKey
                }
            }
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/contacts`, headres_);
            setContactList(Contact.contactList(response.data.data.contacts))
            setLoader(false)
        } catch (error) {
            console.log('error', error);
        }
    }

    async function filter(event, type) {

        if (event.target.value === "" || event.target.value === "Select Contact" || event.target.value === "Select Status") {
            setFilterData(invoices)
            return
        }
        switch (type) {
            case 'invoiceNumber':
                if (invoices && invoices.length != 0) {
                    var result = invoices.filter((e) => String(e.invoice_number).includes(event.target.value));
                    setFilterData(result);

                }
                break;
            case 'customer':
                if (invoices && invoices.length !== 0) {                
                    var result = invoices.filter((e) => {
                        const firstName = e.user.firstName?.toLowerCase().replace(/\s+/g, ' ').trim();
                        const lastName = e.user.lastName?.toLowerCase().replace(/\s+/g, ' ').trim();
                        const fullName = (firstName + " " + lastName)?.toLowerCase();
                        const inputValue = event.target.value?.toLowerCase().replace(/\s+/g, ' ').trim();
                        
                        if (inputValue.includes(" ")) {
                            return fullName === inputValue;
                        } else {
                            return firstName === inputValue || lastName === inputValue;
                        }
                    });
                    
                    setFilterData(result);
                }
                break;
            case 'status':
                if (invoices && invoices.length != 0) {
                    var result = invoices.filter((e) => e.status == event.target.value);
                    setFilterData(result);
                }
                break;
            case 'dateFilter':
                if (invoices && invoices.length != 0) {
                    var result = invoices.filter((e) => moment(e.createdAt).format('YYYY-MM-DD') == event.target.value);
                    setFilterData(result);
                }
                break;
            default:
                break;

        }

    }

    async function exportData() {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const ws = XLSX.utils.json_to_sheet(invoices);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Order" + fileExtension);
    }

    function print() {
        // var css = '@page { size: landscape; }',
        //     head = document.getElementById('invoiceElement'),
        //     style = document.createElement('style');

        // style.type = 'text/css';
        // style.media = 'print';

        // if (style.styleSheet) {
        //     style.styleSheet.cssText = css;
        // } else {
        //     style.appendChild(document.createTextNode(css));
        // }
        // head.appendChild(style);
        // window.print(); table table-striped table-bordered 
        printJS({
            printable: "invoiceTable",
            type: "html",
            targetStyles: ["*"],
            style: ".table .table-striped .table-bordered .tableStyle{ width : 100%}"
        });


    }

    function generatePDF() {
        const element = document.getElementById('invoiceTable');
        const opt = {
            margin: 10.5,
            filename: 'Invoice.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
        };

        html2pdf().from(element).set(opt).save();
    }

    async function handleAction(type, data) {
        navigate("/invoice/create", { state: { invoice: data, type: type } })
    }

    const handleCancel = (data) => {
        setIsModalOpen(true);
        setCancelData(data)
    }

    const handleCancelData = async () => {
        try {
            setLoader(true)
            let user = auth.getAuth();
            const storedAccessToken = localStorage.getItem('accessToken');
            const retrievedData = JSON.parse(storedAccessToken);
            if (user == null) {
                setLoader(false)
                return
            }
            var headres_ = {
                headers: {
                    'authorization': user.data.access_token,
                    'x-access-token': retrievedData.accessToken
                }
            }
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/cancel/invoice`, { external_id: cancelData.invoice_id, site_id: user.data.site.site_id }, headres_);
            setLoader(false)
            setIsModalOpen(false)
            getInvoiceList();
        } catch (error) {
            setLoader(false)
            if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
                auth.disconnectUser()
            }
            console.log('error', error);
        }
    }

    useEffect(() => {
        getInvoiceList();
        getCustomers();
    }, [])

    return (
        <div>

            <section>
                <nav>
                    <div className="menu_bar_btn">
                        <span className="material-symbols-outlined" id="menu_btn">
                            menu
                        </span>

                    </div>
                </nav>
            </section>

            <section>
                <div className="PRODUCT_PAGE_SECTION">
                    <div className="row">
                        <Sidebar />
                        <div className="col-lg-12 col-xxl-10 col-xl-9">
                            <div className="right_side_content">

                                <div className="store_data_table " id="PURCHASE_TABLE">
                                    <div className="row">
                                        <div className="main-title">
                                            <h2>Invoice list</h2>
                                            <Link to="/invoice/create"><button className="filter">
                                                <span className="material-symbols-outlined"> add  </span>{" "} Add New Invoice</button>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="input_top_bar">
                                            <div className="Product_cost">
                                                <div>
                                                    <label >Invoice Number</label>
                                                    <input type="text" placeholder='Search Invoice' onChange={e => filter(e, 'invoiceNumber')} />
                                                </div>

                                                <div>
                                                    <label >Contact</label>
                                                    <select className="form-select" onChange={e => filter(e, 'customer')} >
                                                        <option defaultValue="">{invoices.length !== 0 ? "Select Contact" : "No Contact"}</option>
                                                        {
                                                            invoices.length !== 0 && contactList && contactList.length !== 0 ?
                                                                contactList.map((contact, index) => {
                                                                    return <option defaultValue={contact.id} key={index}>{contact.contactName}</option>
                                                                }) : <></>
                                                        }
                                                    </select>
                                                </div>

                                                <div>
                                                    <label >Status</label>
                                                    <select className="form-select" onChange={e => filter(e, 'status')} >
                                                        <option defaultValue="">{invoices.length !== 0 ? "Select Status" : "No Status"}</option>
                                                        <option value="draft">Draft(Saved)</option>
                                                        <option value="sent">Outstanding(Sent)</option>
                                                        <option value="paid">Paid</option>
                                                        <option value="cancelled">Cancelled</option>
                                                    </select>
                                                </div>

                                                <div>
                                                    <label >Date Range</label>
                                                    <input type="date" onChange={e => filter(e, 'dateFilter')} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="all_btns">
                                        <div className="row">
                                            <div className="col-lg-">
                                                <div className="inner_PDF_btns">
                                                    <button onClick={exportData}>Excel</button>
                                                    <button onClick={generatePDF}>PDF</button>
                                                    <button onClick={print}>Print</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <TableContainer >
                                        <Table id="invoiceTable" className="table table-striped table-bordered " style={{ width: '100%' }}>
                                            <TableHead className="tableHeader">
                                                <TableRow>
                                                    <TableCell>Invoice Number</TableCell>
                                                    <TableCell>Client</TableCell>
                                                    <TableCell>Invoice Date</TableCell>
                                                    <TableCell>Due Date</TableCell>
                                                    <TableCell>Grand Total</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {!loader && invoices && invoices.length != 0 ? filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    ?.map((invoice, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                {/* <TableCell> </TableCell> */}
                                                                <TableCell>{invoice.invoice_number}</TableCell>
                                                                <TableCell>{Contact.showContactList(invoice.user)}</TableCell>
                                                                <TableCell>{moment(invoice.createdAt).format('MM/DD/YYYY')}</TableCell>
                                                                <TableCell>{invoice.due_date ? moment(invoice.due_date).format('MM/DD/YYYY') : ""}</TableCell>
                                                                <TableCell>{"$" + (String(invoice.amount).includes(".") ? (Math.round(invoice.amount * 100) / 100).toFixed(2) : (invoice.amount == null ? 0 : invoice.amount) + ".00")}</TableCell>
                                                                <TableCell>
                                                                    {/* <div className="paid Unpaid">
                                                                    <p>{invoice.status}</p>
                                                                </div> */}
                                                                    {invoice.status == "sent" ?
                                                                        <div className="paid Sent">
                                                                            <p>{invoice.status}</p>
                                                                        </div> :
                                                                        (invoice.status == "draft" ?
                                                                            <div className="paid Saved">
                                                                                <p>{invoice.status}</p>
                                                                            </div>
                                                                            :
                                                                            (invoice.status == "paid" ?
                                                                                <div className="paid Paid">
                                                                                    <p>{invoice.status}</p>
                                                                                </div>
                                                                                :
                                                                                <div className="paid Cancelled">
                                                                                    <p>{invoice.status}</p>
                                                                                </div>
                                                                            )
                                                                        )
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    <div className="action-container">
                                                                        <button className="view-button" onClick={(e) => handleAction("view", invoice)}>View</button>
                                                                        <button className="edit-button" onClick={(e) => handleAction("edit", invoice)}>Edit</button>
                                                                        <button className=" btn btn-danger" onClick={(e) => handleCancel(invoice)} style={invoice.status != "cancelled" ? { display: "inline-block" } : { display: "none" }}>Cancel</button>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                    : (invoices.length == 0 ? <></> : <><Loader /></>)
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={filterData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                className="custom-modal "
                overlayClassName="custom-modal-overlay"
            >
                <div className='customer-card cancelModal'>
                    <div className='cus_inner_card_body '>

                        <div className='row'>
                            <div className='col-11 modal_head_heading'>
                                Are You Sure
                            </div>
                            <div className='col-1 modal_head_close' onClick={closeModal}>
                                <span class="material-symbols-outlined">
                                    cancel
                                </span>
                            </div>
                        </div>
                        <div className='row'>
                            {/* <div className='modal_body_section'>
                                <button type="button" class="btn btn-primary me-2">Yes</button>
                                <button type="button" class="btn btn-primary" >No</button>
                            </div> */}
                            <div className="modal_body_section method">
                                <button className="merchant" onClick={handleCancelData}>Yes</button>
                                <button className="gateway" onClick={closeModal} >No</button>
                            </div>

                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Invoice_List
