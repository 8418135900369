import React, { useEffect, useState } from "react";
import Sidebar from "../Siderbar/Sidebar";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import auth from '../service/auth';
import axios from 'axios';
import moment from 'moment';
import Loader from './loader/Loader';

const Virtual_Terminal = () => {

    const [uniqueContact, setUniqueContact] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getContacts();
    }, []);

    async function getContacts() {
        try {
            setLoader(true)
            let user = auth.getAuth();
            if (user == null) {
                setLoader(false)
                return
            }
            var headres_ = {
                headers: {
                    'authorization': user.data.access_token,
                    locationAPIKeyToken: user.data.data.apiKey
                }
            }
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/contacts`, headres_);
            setUniqueContact(response.data.data.contacts);
            setLoader(false)
        } catch (error) {
            setLoader(false)
            if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
                auth.disconnectUser()
            }
            console.log('error', error);
        }
    }

    return (
        <div>
            <section>
                <nav>
                    <div className="menu_bar_btn">
                        <span className="material-symbols-outlined" id="menu_btn">
                            menu
                        </span>

                    </div>
                </nav>
            </section>
            <section>

                <div className="PRODUCT_PAGE_SECTION">
                    <div className="row">
                        <Sidebar />

                        <div className="col-lg-12 col-xxl-10 col-xl-9">
                            <div className="right_side_content">
                                {loader ? <Loader /> : <></>}
                                <div className="store_data_table " id="vistual_TABLE">
                                    <div className="row">
                                        <div className="main-title">

                                            <h2>Virtual Terminal</h2>
                                        </div>
                                    </div>

                                    <div className="row gx-5">
                                        <div className="col-lg-5">
                                            <div className="Create_Invoice_content Virtul_one">


                                                <form action="">

                                                    <div className="d-flex inner_virtul_div">

                                                        <div className="mb-3">
                                                            <label
                                                                className="form-label">Contacts</label>
                                                            <select className="form-select" >
                                                                <option defaultValue="">{uniqueContact.length != 0 ? "Select Contact" : "No Contact"}</option>
                                                                {
                                                                    uniqueContact && uniqueContact.length != 0 ?
                                                                        uniqueContact.map((contact, index) => {
                                                                            return <option defaultValue={contact.id} key={index}>{contact.contactName}</option>
                                                                        }) : <></>
                                                                }
                                                            </select>

                                                        </div>


                                                        <div className="mb-3">
                                                            <label
                                                                className="form-label">Amount</label>
                                                            <input type="text" className="form-control"
                                                                id="exampleInputPassword1" />
                                                        </div>

                                                        <div className="mb-3">
                                                            <label
                                                                className="form-label">Invoice</label>
                                                            <input type="text" className="form-control"
                                                                id="exampleInputPassword1" />
                                                        </div>

                                                        <div className="mb-3 Description_div">
                                                            <label
                                                                className="form-label">Description</label>
                                                            <textarea name=""></textarea>
                                                        </div>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>

                                        <div className="col-lg-5">
                                            <div className="Create_Invoice_content Virtul_two">

                                                <div className="bank_cards">
                                                    <div className="Debit-Credit active">
                                                        <span className="material-symbols-outlined">
                                                            credit_card
                                                        </span>
                                                        <p>Debit/Credit Card</p>
                                                    </div>
                                                    <div className="Bank_Account">
                                                        <span className="material-symbols-outlined">
                                                            account_balance
                                                        </span>
                                                        <p>Bank Account</p>
                                                    </div>
                                                </div>


                                                <div className="mb-3">
                                                    <label className="form-label">Name</label>
                                                    <input type="text" className="form-control" id="exampleInputPassword1" />
                                                </div>

                                                <div className="mb-3">
                                                    <label className="form-label">Card Number</label>
                                                    <input id="ccn" className="form-control" type="tel" inputMode="numeric" pattern="[0-9\s]{13,19}"
                                                        autoComplete="cc-number" maxLength="19"
                                                        placeholder="xxxx xxxx xxxx xxxx" />
                                                </div>

                                                <div className="d-flex">
                                                    <div className="mb-3">
                                                        <label className="form-label">Billing Zip Code</label>
                                                        <input type="text" className="form-control" id="exampleInputPassword1" />
                                                    </div>
                                                    <div className="mb-3">

                                                        <div className="card_types">
                                                            <img src="./sourse/credit-card.png" alt="" />
                                                            <img src="./sourse/credit-card (1).png" alt="" />
                                                            <img src="./sourse/credit-card (2).png" alt="" />
                                                            <img src="./sourse/credit-card (3).png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <button className="sm px-4 rounded-none flex-grow hyfin-k2tlbd" id="">Process Payment</button>



                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </div>
    )
}

export default Virtual_Terminal
