import React, { useState, useEffect } from "react";
import Sidebar from "../Siderbar/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import auth from '../service/auth';
import axios from 'axios';
import moment from 'moment';
import Loader from './loader/Loader';
import copy from "copy-to-clipboard";
import TablePagination from "@mui/material/TablePagination";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const Payment_links = () => {
  const navigate = useNavigate();

  const [invoices, setInvoices] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [clipboardClass, setclipboardClass] = useState();
  const [clickIndex, setclickIndex] = useState();
  const [isCopied, setIsCopied] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getInvoiceList();
  }, [])

  async function getInvoiceList() {
    try {
      setLoader(true)
      let user = auth.getAuth();
      if (user == null) {
        setLoader(false)
        return
      }
      var headres_ = {
        headers: {
          'authorization': user.data.access_token,
          locationapikeytoken: user.data.data.apiKey
        }
      }
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/invoices/customer`, headres_);

      var formattedData = [];
      for (let i = 0; i < response.data.data.length; i++) {
        if (response.data.data[i].line_items.length != 0) {
          for (let j = 0; j < response.data.data[i].line_items.length; j++) {
            var exp_date = response.data.data[i].expire_at == null ? '' : response.data.data[i].expire_at;
            var active = false;
            if (exp_date == "") {
              active = true;
            } else {
              const date1 = new Date();
              const date2 = new Date(exp_date);
              active = (date1 == date2 || date1 < date2 ? true : false);


            }

            var obj = {
              invoice_id: response.data.data[i].id,
              invoice_number: response.data.data[i].invoice_number,
              url: response.data.data[i].url,
              active: active,
              product_name: response.data.data[i].line_items[j].product.name,
              created: response.data.data[i].createdAt,
              updated: response.data.data[i].updatedAt
            }
            formattedData.push(obj)

          }
        }
      }
      setInvoices(formattedData);
      setSearchFilter(formattedData)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
        auth.disconnectUser()
      }
      console.log('error', error);
    }
  }

  async function filter(event) {
    if (event == "") {
      setSearchFilter(invoices);
      return
    }
    if (invoices && invoices.length != 0) {
      var result = invoices.filter((e) => String(e.invoice_number).includes(event));
      setSearchFilter(result);
    }
  }

  const copyToClipboard = (url, index) => {
    copy(url);
    setclipboardClass(url);
    setIsCopied(true);
    setclickIndex(index);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <div>

      <section>
        <nav>
          <div className="menu_bar_btn">
            <span className="material-symbols-outlined" id="menu_btn">
              menu
            </span>
          </div>
        </nav>
      </section>

      <section>
        <div className="PRODUCT_PAGE_SECTION">
          <div className="row">
            <Sidebar />
            <div className="col-lg-12 col-xxl-10 col-xl-9">
              <div className="right_side_content">
                <div className="store_data_table">
                  <div className="row">
                    <div className="main-title">
                      <h2>Payment Links</h2>
                      {isCopied && (
                        <div className="alert alert-success copiedData" >
                          <strong>Copied!</strong> {clipboardClass}
                        </div>
                      )}
                      <button className="Export"
                      // onClick={(e) => navigate('/invoice/create')}
                      > New Payments Links </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="main-title">
                      <div className="searchfilter">
                        <label className="searchLabel">  {" "} Search:   </label>
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="example"
                          onChange={e => filter(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <TableContainer >
                    <Table id="example" className="table table-striped table-bordered" style={{ width: "100%" }}>
                      <TableHead className="tableHeader">
                        <TableRow>
                          <TableCell>Invoice Number</TableCell>
                          <TableCell>Payment Link</TableCell>
                          <TableCell>Active</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Created</TableCell>
                          <TableCell>Update</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!loader && invoices && invoices.length != 0 ? searchFilter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          ?.map((invoice, index) => {
                            return (
                              <TableRow key={invoice.invoice_id}>
                                {/* <TableCell> </TableCell> */}
                                <TableCell>{invoice.invoice_number} </TableCell>
                                <TableCell style={{ cursor: "pointer" }} onClick={() => copyToClipboard(invoice.url, index)}>
                                  {invoice.url.substring(0, 10) + "*".repeat(10) + invoice.url.substring(invoice.url.length - 6)}
                                </TableCell>
                                <TableCell className={invoice.active ? "green_check" : "red_check"}><span className="material-symbols-outlined">{invoice.active ? "check_circle" : "cancel"}</span></TableCell>
                                <TableCell>{invoice.product_name}</TableCell>
                                <TableCell>{moment(invoice.created).format('DD/MM/YYYY hh:mm A')}</TableCell>
                                <TableCell>{moment(invoice.updated).format('DD/MM/YYYY hh:mm A')}</TableCell>
                              </TableRow>
                            );
                          })
                          : (invoices.length == 0 ? <></> : <><Loader /></>)
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={invoices.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="add_product_popup">
        <div className="inner_popup">
          <div className="popup_header">
            <h4>Add Product</h4>
            <span className="material-symbols-outlined" id="close">
              close
            </span>
          </div>

          <div className="popup_body">
            <div className="first_one_time">
              {" "}
              <label >
                Product Type <span>*</span>
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                id="Product"
              >
                <option defaultValue>Open this select</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>

            <div>
              {" "}
              <label >
                Product SKU <span>*</span>
              </label>
              <input type="text" />
            </div>

            <div>
              {" "}
              <label >
                Product Name <span>*</span>
              </label>
              <input type="text" />
            </div>

            <div className="Product_cost">
              <div>
                <label >
                  Product Cost $<span>*</span>
                </label>
                <input type="text" />
              </div>

              <div>
                <label >One Time Setup Fee Product Cost</label>
                <input type="text" />
              </div>
            </div>
          </div>

          <div className="radio_have">
            <input type="checkbox" />
            <p>Have Trial?</p>
          </div>

          <div className="editer_sec">
            <label >
              Description <span>*</span>
            </label>
            <textarea id="editor"></textarea>
            <button type="button" id="Save_changes">
              <span className="material-symbols-outlined">save</span> Save Changes
            </button>
          </div>
        </div>
      </div>

      <script src="js/main.js"></script>

    </div>
  );
};

export default Payment_links;
