import React, { useState, useEffect } from 'react';
import Sidebar from "../Siderbar/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import auth from '../service/auth';
import axios from 'axios';
import moment from 'moment';
import Loader from './loader/Loader';
import Modal from "react-modal";
import copy from "copy-to-clipboard";
import Contact from '../service/contact';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

const Invoice_List = () => {
    const navigate = useNavigate();

    const [invoices, setInvoices] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [clipboardClass, setclipboardClass] = useState();
    const [clickIndex, setclickIndex] = useState();
    const [isCopied, setIsCopied] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => setPage(newPage);

    const [pageModal, setPageModal] = useState(0);
    const [rowsPerPageModal, setRowsPerPageModal] = useState(10);
    const handleChangePageModal = (event, newPage) => setPageModal(newPage);

    const openModal = () => {
        setIsModalOpen(true);
    }
    const closeModal = () => {
        setIsModalOpen(false);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeRowsPerPageModal = (event) => {
        setRowsPerPageModal(+event.target.value);
        setPageModal(0);
    };

    const copyToClipboard = (url, index) => {
        copy(url);
        setclipboardClass(url);
        setIsCopied(true);
        setclickIndex(index);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };

    async function getInvoiceList() {
        try {
            setLoader(true)
            let user = auth.getAuth();
            if (user == null) {
                setLoader(false)
                return
            }
            var headres_ = {
                headers: {
                    'authorization': user.data.access_token
                }
            }
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/customers`, headres_);
            setContactList(response.data.data);
            setLoader(false)
        } catch (error) {
            setLoader(false)
            if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
                auth.disconnectUser()
            }
            console.log('error', error);
        }
    }

    async function modalListing(data) {
        try {
            openModal();
            if (contactList.length != 0) {
                setInvoices(data.user.invoices)
            }
        } catch (error) {

        }
    }

    async function handleAction(type, data) {
        navigate("/invoice/create", { state: { invoice: data, type: type } })
    }

    useEffect(() => {
        getInvoiceList();
    }, [])

    return (
        <div>

            <section>
                <nav>
                    <div className="menu_bar_btn">
                        <span className="material-symbols-outlined" id="menu_btn">
                            menu
                        </span>

                    </div>
                </nav>
            </section>

            <section>
                <div className="PRODUCT_PAGE_SECTION">
                    <div className="row">
                        <Sidebar />
                        <div className="col-lg-12 col-xxl-10 col-xl-9">
                            <div className="right_side_content">

                                <div className="store_data_table " id="PURCHASE_TABLE">
                                    <div className="row">
                                        <div className="main-title">
                                            <h2>Contacts</h2>
                                            {isCopied && (
                                                <div className="alert alert-success copiedData" >
                                                    <strong>Copied!</strong> {clipboardClass}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <Modal
                                        isOpen={isModalOpen}
                                        onRequestClose={closeModal}
                                        contentLabel="Example Modal"
                                        className="custom-modal"
                                        overlayClassName="custom-modal-overlay"
                                    >
                                        <div className='customer-card'>
                                            <div className='cus_inner_card_body'>
                                                <div className='close-modal' >
                                                    <div className='close__'>
                                                        <span class="material-symbols-outlined" onClick={closeModal}>
                                                            cancel
                                                        </span>
                                                    </div>
                                                </div>

                                                <TableContainer >
                                                    <Table id="invoiceTable" className="table table-striped table-bordered " style={{ width: '100%' }}>
                                                        <TableHead className="tableHeader">
                                                            <TableRow>
                                                                <TableCell>Invoice Number</TableCell>
                                                                <TableCell>Url</TableCell>
                                                                <TableCell>Description</TableCell>
                                                                <TableCell>Due Date</TableCell>
                                                                <TableCell>Grand Total</TableCell>
                                                                <TableCell>Status</TableCell>
                                                                <TableCell>Action</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {!loader && invoices && invoices.length != 0 ? invoices?.slice(pageModal * rowsPerPageModal, pageModal * rowsPerPageModal + rowsPerPageModal)
                                                                ?.map((invoice, index) => {
                                                                    return (
                                                                        <TableRow key={invoice.id}>
                                                                            <TableCell>{invoice.invoice_number}</TableCell>
                                                                            <TableCell style={{ cursor: "pointer" }} onClick={() => copyToClipboard(invoice.url, index)}>
                                                                                {invoice.url ? invoice.url.substring(0, 10) + "*".repeat(10) + invoice.url.substring(invoice.url.length - 6) : "NA"}
                                                                            </TableCell>
                                                                            <TableCell>{invoice.description}</TableCell>
                                                                            <TableCell>{invoice.due_date != null ? moment(invoice.due_date).format('MM/DD/YYYY') : ''}</TableCell>
                                                                            <TableCell>{"$" + (invoice.webhook.length != 0 && invoice.webhook[0] && invoice.webhook[0].amount ? (Math.round(invoice.webhook[0].amount * 100) / 100).toFixed(2) : (String(invoice.amount).includes(".") ? (Math.round(invoice.amount * 100) / 100).toFixed(2) : (invoice.amount == null ? 0 : invoice.amount) + ".00"))}</TableCell>
                                                                            <TableCell>
                                                                                {/* {invoice.status == "sent" ?
                                                                                    <div className="paid">
                                                                                        <p>{invoice.status}</p>
                                                                                    </div> :
                                                                                    <div className="paid Sent">
                                                                                        <p>{invoice.status}</p>
                                                                                    </div>
                                                                                } */}
                                                                                {invoice.status == "sent" ?
                                                                                    <div className="paid Sent">
                                                                                        <p>{invoice.status}</p>
                                                                                    </div> :
                                                                                    (invoice.status == "draft" ?
                                                                                        <div className="paid Saved">
                                                                                            <p>{invoice.status}</p>
                                                                                        </div>
                                                                                        :
                                                                                        (invoice.status == "paid" ?
                                                                                            <div className="paid Paid">
                                                                                                <p>{invoice.status}</p>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="paid Cancelled">
                                                                                                <p>{invoice.status}</p>
                                                                                            </div>
                                                                                        )
                                                                                    )
                                                                                }

                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <div className="action-container">
                                                                                    <button className="view-button" onClick={(e) => handleAction("view", invoice)}>View</button>
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })
                                                                : (invoices.length == 0 ? <></> : <><Loader /></>)
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10]}
                                                    component="div"
                                                    count={invoices.length}
                                                    rowsPerPage={rowsPerPageModal}
                                                    page={pageModal}
                                                    onPageChange={handleChangePageModal}
                                                    onRowsPerPageChange={handleChangeRowsPerPageModal}
                                                />
                                            </div>
                                        </div>
                                    </Modal>

                                    <TableContainer >
                                        <Table id="invoiceTable" className="table table-striped table-bordered " style={{ width: '100%' }}>
                                            <TableHead className="tableHeader">
                                                <TableRow>
                                                    <TableCell>First Name</TableCell>
                                                    <TableCell>Last Name</TableCell>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell>Phone</TableCell>
                                                    <TableCell>Invoices</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {!loader && contactList && contactList.length != 0 ? contactList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    ?.map((users, index) => {
                                                        return (
                                                            <TableRow key={users.user.data.id}>
                                                                {/* <TableCell> </TableCell> */}
                                                                <TableCell>{Contact.showContactFirstName(users.user.data)}</TableCell>
                                                                <TableCell>{Contact.showContactLastName(users.user.data)}</TableCell>
                                                                <TableCell>{users.user.data.email}</TableCell>
                                                                <TableCell>{users.user.data.phone}</TableCell>
                                                                <TableCell onClick={(e) => modalListing(users)}>
                                                                    <div className="action-container">
                                                                        <button className="view-button" >View</button>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                    : (contactList.length == 0 ? <></> : <><Loader /></>)
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={contactList.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </div>
                            </div>
                        </div>
                        <></>


                    </div>
                </div>
            </section>
        </div>
    )
}

export default Invoice_List
