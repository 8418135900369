import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../service/auth";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LogoGhl from "../service/logoGHL";
import axios from 'axios';

const Login = () => {
    const navigate = useNavigate();
    const [apiKey, setApiKey] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [LocationId, setLocationId] = useState("");
    const [SiteId, setSiteId] = useState("");
    const [Connecting, setConnecting] = useState(false);
    const [visible, setVisible] = useState(false);
    const [ServerError, setServerError] = useState("");
    const [inputErrors, setInputErrors] = useState({
        LocationId: false,
        apiKey: false,
        SiteId: false,
        accessToken: false,
    });

    useEffect(() => {
        getLocationID();
    }, []);

    useEffect(() => {
        resize();
    }, [apiKey, accessToken]);

    const resize = () => {
        const textarea = document.getElementById('autosize');
        const textarea2 = document.getElementById('accessToken');
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight - 2}px`;
        }
        if (textarea2) {
            textarea2.style.height = 'auto';
            textarea2.style.height = `${textarea2.scrollHeight - 2}px`;
        }
    };

    function getLocationID() {
        try {
            // Location ID
            var l_id = localStorage.getItem("location_id");
            var w_href = window.location.href ? window.location.href.split(":") : "";
            if (l_id) {
                if (l_id == w_href[2]) {
                    setLocationId(l_id);
                    localStorage.setItem("location_id", l_id);
                } else {
                    if (w_href[2] == "id") {
                        setLocationId(l_id);
                        localStorage.setItem("location_id", l_id);
                    } else {
                        setLocationId(w_href[2]);
                        localStorage.setItem("location_id", w_href[2]);
                    }
                }
            } else {
                localStorage.setItem("location_id", w_href[2]);
                setLocationId(w_href[2]);
            }
        } catch (error) { }
    }

    const handleInputChange = (e, inputName) => {
        const value = e.target.value;
        setServerError("");
        const newInputErrors = { ...inputErrors, [inputName]: !value };
        setInputErrors(newInputErrors);
        switch (inputName) {
            case "LocationId":
                setLocationId(value);
                break;
            case "apiKey":
                setApiKey(value);
                break;
            case "SiteId":
                setSiteId(value);
                break;
            case "accessToken":
                setAccessToken(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!LocationId || !apiKey || !SiteId || !accessToken) {
            setInputErrors({
                LocationId: !LocationId,
                apiKey: !apiKey,
                SiteId: !SiteId,
                accessToken: !accessToken,
            });
            return;
        }
        try {
            setConnecting(true);
            var postData = {
                locationAPIKeyToken: apiKey,
                location_id: LocationId,
                site_id: SiteId,
                access_token: accessToken,
            };
            const responseData = await axios.post(`${process.env.REACT_APP_URL}/api`, postData);
            auth.connectionUser(responseData);
            localStorage.setItem("isConnection", true);
            let data = { accessToken, SiteId };
            const dataString = JSON.stringify(data);
            localStorage.setItem("accessToken", dataString);
            navigate("/dashboard");
        } catch (error) {
            console.log('error', error);
            setConnecting(false);
        }
    };

    return (
        <div className="container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="Configuration-section">
                            <div className="brandLogo">
                                <LogoGhl />
                            </div>
                            <div className="inputs_Configuration">
                                <h2>Configuration</h2>
                                <form >
                                    <label className="form-label">
                                        Location Id
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip-right">GHL Location Id</Tooltip>}
                                        >
                                            <i className="bi bi-info-circle-fill"></i>
                                        </OverlayTrigger>

                                        <a target="_blank" rel="noreferrer" href="https://app.gohighlevel.com/sub-accounts/">How to get</a>
                                    </label>
                                    <input
                                        type="text"
                                        id="LocationId"
                                        className={inputErrors.LocationId ? "errorColor form-control" : "form-control"}
                                        value={LocationId}
                                        onChange={(e) => handleInputChange(e, "LocationId")}
                                        placeholder="Enter your Location Id"
                                        disabled={LocationId ? true : false}
                                    />

                                    <label className="form-label">
                                        Location API Key Token
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip-right">GHL Location API Key</Tooltip>}
                                        >
                                            <i className="bi bi-info-circle-fill"></i>
                                        </OverlayTrigger>
                                        <a target="_blank" rel="noreferrer" href="https://app.gohighlevel.com/settings/api_key">How to get</a>
                                    </label>
                                    <textarea
                                        type="text"
                                        id="autosize"
                                        className={inputErrors.apiKey ? "autosize textarea-no-scrollbar errorColor " : "autosize textarea-no-scrollbar "}
                                        rows="1"
                                        value={apiKey}
                                        onChange={(e) => handleInputChange(e, "apiKey")}
                                        placeholder="Enter your API key"
                                    />
                                    <p>You can find it on Setting -&gt; Business Profile -&gt; General Information</p>

                                    <label className="form-label">
                                        Site Id<OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip-right">360Five Checkout Site Id</Tooltip>}
                                        >
                                            <i className="bi bi-info-circle-fill"></i>
                                        </OverlayTrigger>
                                        <a target="_blank" rel="noreferrer" href="https://stage.360fivecheckout.io/docs/2.0/introduction">How to get</a>
                                    </label>
                                    <input
                                        type="text"
                                        id="SiteId"
                                        className={inputErrors.SiteId ? "errorColor form-control" : "form-control"}
                                        value={SiteId}
                                        onChange={(e) => handleInputChange(e, "SiteId")}
                                        placeholder="Enter your Site Id"
                                    />

                                    <label className="form-label">
                                        Access Token
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip-right">360Five Checkout Access Token</Tooltip>}
                                        ><i className="bi bi-info-circle-fill"></i>
                                        </OverlayTrigger>
                                        <a target="_blank" rel="noreferrer" className="aTagLink" href="https://stage.360fivecheckout.io/docs/2.0/authentication">How to get</a>
                                    </label>
                                    <textarea
                                        type="text"
                                        id="accessToken"
                                        className={inputErrors.accessToken ? "autosize textarea-no-scrollbar errorColor " : "autosize textarea-no-scrollbar"}
                                        rows="1"
                                        value={accessToken}
                                        onChange={(e) => handleInputChange(e, "accessToken")}
                                        placeholder="Enter your access token"
                                    />

                                    <div className="create-connection">
                                        <button type="submit"
                                            className="filter mt-3 m-auto"
                                            onClick={handleSubmit}
                                        >
                                            {Connecting ? "Connecting..." : "Make Connection"}{" "}
                                        </button>
                                        <div className="backBtn">
                                            <button type="button"
                                                className="mt-3 m-auto connectionBackBtn" onClick={(e) => setVisible(!visible)}>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;