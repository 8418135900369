import React, { Children, useEffect, useState } from "react";
import Sidebar from "../Siderbar/Sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import auth from '../service/auth';
import axios from 'axios';
import Contact from '../service/contact';
import Loader from "./loader/Loader";
import moment from "moment/moment";

const Create_Invoice = () => {
  const Navigate = useNavigate();
  const location = useLocation();

  const [Description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [memo, setMemo] = useState("");

  const [expire, setExpire] = useState();
  const [allProduct, setAllProduct] = useState([]);
  const [tax, setTax] = useState(true);
  const [Amount, setAmount] = useState();
  const [invAction, setInvAction] = useState("add");
  const [Email, setEmail] = useState("");
  const [product, setProduct] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tip, setTip] = useState(false);
  const [companyTax, setCompanyTax] = useState(0);
  const [discount, setDiscount] = useState("no");
  const [discountValue, setDiscountValue] = useState("");
  const [file, setFile] = useState();
  const [errorColor, setErrorColor] = useState(false);
  const [uniqueContact, setUniqueContact] = useState([]);
  const [productList, setProductList] = useState([]);
  const [ServerError, setServerError] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [Total, setTotal] = useState(0);
  const [invTotal, setInvoiceTotal] = useState(0);
  const [disAmount, setDiscountAmount] = useState(0);
  const [inputErrors, setInputErrors] = useState({
    Description: false,
    Amount: false,
    orderType: false,
    name: false,
    invTotal: false,
    file: false,
    frequency: false,
    frequencyUnits: false
  });
  const [newProduct, setNewProduct] = useState(false);
  const [productLineItem, setProductLineItem] = useState([{
    unique: 0,
    external_id: "",
    description: "",
    name: "",
    unit_price: 0,
    qty: 0,
    total: "",
    taxable: true,
    amount: ""
  }])

  const [newProductLineItem, setNewProductLineItem] = useState([{
    unique: 0,
    external_id: "",
    description: "",
    name: "",
    unit_price: 0,
    sku: "",
    qty: 0,
    total: "",
    taxable: true,
    amount: ""
  }])


  const [orderType, setOrderType] = useState('');
  const [showRecurringOptions, setShowRecurringOptions] = useState(false);
  const [frequency, setFrequency] = useState('');
  const [frequencyUnits, setFrequencyUnits] = useState('');
  const [frequencyUnitsError, setFrequencyUnitsError] = useState('');
  const [frequencyError, setFrequencyError] = useState('');
  const [subs_external_id, set_subs_external_id] = useState('');

  useEffect(() => {
    getCompanyTax();
    getContacts();
    getProductList();
  }, []);

  function getCompanyTax() {
    try {
      let user = auth.getAuth();
      if (user == null) {
        setLoader(false)
        return
      }
      var ind = user.data.site.connected_with.findIndex((e) => e.locationAPIKeyToken === user.data.data.apiKey);
      if (ind > -1) {
        setCompanyTax(user.data.site.connected_with[ind].tax ? user.data.site.connected_with[ind].tax : 0);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    geEditValues();
  }, [invAction])

  useEffect(() => {
    if (invAction === "add") {
      if (productLineItem.length == 1 && productLineItem[0].qty === 0) {
        setAmount(0);
      } else {
        var sum_ = 0;
        for (let i = 0; i < productLineItem.length; i++) {
          if (tax) {
            if (productLineItem[i].taxable) {
              sum_ = sum_ + (Number(productLineItem[i].unit_price) * Number(productLineItem[i].qty)) + ((Number(productLineItem[i].unit_price) * Number(productLineItem[i].qty)) * Number(companyTax)) / 100;
            } else {
              sum_ = sum_ + (Number(productLineItem[i].unit_price) * Number(productLineItem[i].qty))
            }
          } else {
            sum_ = sum_ + (Number(productLineItem[i].unit_price) * Number(productLineItem[i].qty))
          }
        }
        setAmount(sum_);
      }
    }
    if (invAction == "edit") {
      var sum_ = 0;
      for (let i = 0; i < productLineItem.length; i++) {
        if (tax) {
          if (productLineItem[i].taxable) {
            sum_ = sum_ + (Number(productLineItem[i].unit_price) * Number(productLineItem[i].qty)) + ((Number(productLineItem[i].unit_price) * Number(productLineItem[i].qty)) * Number(companyTax)) / 100;
          } else {
            sum_ = sum_ + (Number(productLineItem[i].unit_price) * Number(productLineItem[i].qty))
          }
        } else {
          sum_ = sum_ + (Number(productLineItem[i].unit_price) * Number(productLineItem[i].qty))
        }
      }
      setAmount(sum_);
    }
  }, [productLineItem])

  async function getProductList() {
    try {
      let user = auth.getAuth();
      if (user == null) {
        setLoader(false)
        return
      }
      var headres_ = {
        headers: {
          'authorization': user.data.access_token,
        }
      }
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/products`, headres_);
      if (response.data.data) {
        setProductList(response.data.data)
      }
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message && error.response.data.message === "jwt expired") {
        auth.disconnectUser()
      }
      console.log('error', error);
    }
  }

  async function getContacts() {
    try {
      setLoader(true)
      let user = auth.getAuth();
      if (user == null) {
        setLoader(false)
        return
      }
      var headres_ = {
        headers: {
          'authorization': user.data.access_token,
          locationAPIKeyToken: user.data.data.apiKey
        }
      }
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/contacts`, headres_);
      setUniqueContact(Contact.contactList(response.data.data.contacts))
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log('error', error);
    }
  }

  async function geEditValues() {
    console.log(location.state, "location.state");
    if (location.state) {
      let user = auth.getAuth();
      if (user == null) {
        setLoader(false)
        return
      }
      var headres_ = {
        headers: {
          'authorization': user.data.access_token,
        }
      }
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/products`, headres_);
      setAllProduct(response.data.data);

      if (location.state.type == "view" || location.state.type == "edit") {
        if (productLineItem.length == 1 && productLineItem[0].qty == 0) {
          setAmount(location.state.invoice.amount)
        } else {
          var sum_ = 0;
          for (let i = 0; i < productLineItem.length; i++) {
            sum_ = sum_ + (Number(productLineItem[i].unit_price) * Number(productLineItem[i].qty))
          }
          setAmount(sum_);
        }
      }
      setInvAction(location.state.type);
      setDiscount(location.state.invoice.discountType ? location.state.invoice.discountType : "no")
      setDescription(location.state.invoice.description);
      setName(Contact.showContactFirstName(location.state.invoice.user) + " " + Contact.showContactLastName(location.state.invoice.user));
      // setName(location.state.invoice.user?.firstName);
      setPhone(location.state.invoice.user.phone);
      setMemo(location.state.invoice.customer_memo);
      setExpire(location.state.invoice.due_date ? moment(location.state.invoice.due_date).format("yyyy-MM-DD") : location.state.invoice.expire_at);
      setTax(location.state.invoice.tax);
      setEmail(location.state.invoice.user.email);
      setProduct(location.state.invoice.line_items.length == 0 ? false : true);
      setTip(location.state.invoice.tippingEnabled);
      setDiscountAmount(location.state.invoice.discount ? Number(location.state.invoice.discount) : 0);
      setDiscountValue(location.state.invoice.discountValue ? Number(location.state.invoice.discountValue) : 0)
      var p_line = [];
      if (location.state.invoice.line_items.length != 0) {
        for (let i = 0; i < location.state.invoice.line_items.length; i++) {
          var ind = allProduct.findIndex((e) => e.name == location.state.invoice.line_items[i].product.name);
          var obj = {
            unique: i,
            external_id: location.state.invoice.line_items[i].product.external_id,
            description: location.state.invoice.line_items[i].description,
            name: location.state.invoice.line_items[i].product.name,
            unit_price: location.state.invoice.line_items[i].unit_price,
            qty: Number(location.state.invoice.line_items[i].qty),
            taxable: (ind > -1 ? allProduct[ind].taxable : false),
            amount: (ind > -1 && allProduct[ind].taxable ? (Number(location.state.invoice.line_items[i].unit_price) * Number(location.state.invoice.line_items[i].qty) * Number(companyTax)) / 100 : 0),
            total: (ind > -1 && allProduct[ind].taxable ? (Number(location.state.invoice.line_items[i].unit_price) * Number(location.state.invoice.line_items[i].qty) + (Number(location.state.invoice.line_items[i].unit_price) * Number(location.state.invoice.line_items[i].qty) * Number(companyTax)) / 100) : Number(location.state.invoice.line_items[i].unit_price) * Number(location.state.invoice.line_items[i].qty))
          }
          p_line.push(obj)
        }
        var sum = 0;
        var total = 0;
        for (let j = 0; j < location.state.invoice.line_items.length; j++) {
          var ind = allProduct.findIndex((e) => e.name == location.state.invoice.line_items[j].product.name);

          sum = sum + Number(location.state.invoice.line_items[j].unit_price) * Number(location.state.invoice.line_items[j].qty);

          total = total + (ind > -1 && allProduct[ind].taxable ? (location.state.invoice.discountType != "no" ? ((Number(location.state.invoice.line_items[j].unit_price) * Number(location.state.invoice.line_items[j].qty) + (Number(location.state.invoice.line_items[j].unit_price) * Number(location.state.invoice.line_items[j].qty) * Number(companyTax)) / 100) - (location.state.invoice.discount ? Number(location.state.invoice.discount) : 0)) : (Number(location.state.invoice.line_items[j].unit_price) * Number(location.state.invoice.line_items[j].qty) + (Number(location.state.invoice.line_items[j].unit_price) * Number(location.state.invoice.line_items[j].qty) * Number(companyTax)) / 100)) : Number(location.state.invoice.line_items[j].unit_price) * Number(location.state.invoice.line_items[j].qty));
        }
        setInvoiceTotal(total)
        setSubTotal(sum)
      }
      setProductLineItem(p_line)
      setFrequency(location.state.invoice.frequency)
      setFrequencyUnits(location.state.invoice.frequencyUnits)
      setOrderType(location.state.invoice.orderType)
      set_subs_external_id(location.state.invoice.subs_external_id)
      if (location.state.invoice.orderType === "recurringOrder") {
        setShowRecurringOptions(true)
      }

    }
  }

  

  async function getDiscountData(value, type) {
    try {
      const getValue = [...(productLineItem.length == 1 && productLineItem[0].qty == 0 ? [] : productLineItem), ...(newProductLineItem.length == 1 && newProductLineItem[0].qty == 0 ? [] : newProductLineItem)];
      var total = 0;
      var subTotal = 0
      for (let j = 0; j < getValue.length; j++) {
        var ind = productList.findIndex((e) => e.name == getValue[j].name);
        total = total + (ind > -1 && productList[ind].taxable ? (Number(getValue[j].unit_price) * Number(getValue[j].qty) + (Number(getValue[j].unit_price) * Number(getValue[j].qty) * Number(companyTax)) / 100) : Number(getValue[j].unit_price) * Number(getValue[j].qty));
        subTotal = subTotal + Number(getValue[j].unit_price) * Number(getValue[j].qty);
      }
      if (type == "percentage") {
        var subValue = Number(subTotal) - (Number(subTotal) * Number(value)) / 100;
        var v__ = (Number(subTotal) * Number(value)) / 100;
        var valuee = Number(total) - Number(v__);
        setSubTotal(subValue)
        setDiscountAmount(v__);
        setInvoiceTotal(valuee);
      }
      if (discount == "dollar") {
        var subValue = Number(subTotal) - Number(value);
        var v__ = Number(value);
        var valuee = Number(total) - Number(value);
        setSubTotal(subValue);
        setDiscountAmount(v__);
        setInvoiceTotal(valuee);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const handleInputChange = (e, inputName, proNew) => {
    const value = e.target.value;
    setServerError("")
    const newInputErrors = { ...inputErrors, [inputName]: !value };
    setInputErrors(newInputErrors);
    switch (inputName) {
      case 'name':
        setName(value);
        var contactData = uniqueContact.filter((e) => e.contactName.trim() === value.trim());
        if (contactData.length !== 0) {
          setEmail((contactData[0].email == null ? "" : contactData[0].email));
          setPhone((contactData[0].phone == null ? "" : contactData[0].phone));
        } else {
          setEmail("");
          setPhone("");
        }
        break;
      case 'Description':
        setDescription(value);
        break;
      case 'memo':
        setMemo(value);
        break;
      case 'expire':
        setExpire(value);
        break;
      case 'tax':
        setTax(!tax)
        break;
      case 'discount':
        setDiscount(value);
        break;
      case 'discountValue':
        const ree = /^[0-9\b]+$/;
        if (value === '' || ree.test(value)) {
          setDiscountValue(value);
        }
        if (discount == "percentage") {
          getDiscountData(value, "percentage");
        }
        if (discount == "dollar") {
          getDiscountData(value, "dollar");
        }
        break;
      case 'file':
        setFile(e.target.files[0]);
        break;
      case 'addProduct':
        if (value == 'true' || value == true) {
          setProduct(true);
          if (location && location.state && location.state.type == "edit") {
            setProductLineItem([{
              unique: 0,
              external_id: "",
              description: "",
              name: "",
              unit_price: 0,
              qty: 0,
              taxable: true,
              amount: ''
            }])
          }
        } else {
          setProduct(false);
        }
        break;
      case 'addNewProduct':
        if (value == 'true' || value == true) {
          setNewProduct(true);
          if (location && location.state && location.state.type == "edit") {
            setNewProductLineItem([{
              unique: 0,
              external_id: "",
              description: "",
              name: "",
              unit_price: 0,
              sku: "",
              qty: 0,
              total: "",
              taxable: true,
              amount: ""
            }])
          }
        } else {
          setNewProduct(false);
        }

        break;

      case 'orderType':
        setOrderType(value);
        setShowRecurringOptions(value === 'recurringOrder');
        if (value !== 'recurringOrder') {
          setFrequency('');
          setFrequencyUnits('');
          setFrequencyError('');
          setFrequencyUnitsError('');
        }
        break
      case 'frequency':
        setFrequency(value);
        if (value) {
          setFrequencyError("")
        }
        break;
      case 'frequencyUnits':
        const numericValue = Number(value);
        if (numericValue < 0) return;
        if (value) {
          setFrequencyUnitsError("")
        }
        setFrequencyUnits(numericValue);
        break;
      default:
        break;
    }
  };

  async function getProductData(e, rowIndex, type) {
    try {
      if (type == 'name') {
        if (e != "Select") {
          const newlineItemState = [...productLineItem];
          var productData = productList.filter((ele) => ele.name == e);
          if (productData.length != 0) {
            newlineItemState[rowIndex].external_id = productData[0].external_id
            newlineItemState[rowIndex].description = productData[0].description;
            newlineItemState[rowIndex].name = productData[0].name;
            newlineItemState[rowIndex].unit_price = productData[0].unit_price;
            newlineItemState[rowIndex].taxable = productData[0].taxable;
            setProductLineItem(newlineItemState)
          }
        } else {
          const newlineItemState = [...productLineItem];
          newlineItemState[rowIndex].external_id = ""
          newlineItemState[rowIndex].description = "";
          newlineItemState[rowIndex].name = e;
          newlineItemState[rowIndex].unit_price = 0;
          newlineItemState[rowIndex].taxable = true;
          setProductLineItem(newlineItemState)
        }
      }
      if (type == 'quantity') {
        const newlineItemStates = [...productLineItem];
        var value = Number(e);
        newlineItemStates[rowIndex].qty = value;
        if (newlineItemStates[rowIndex].taxable && tax) {
          newlineItemStates[rowIndex].amount = (Number(newlineItemStates[rowIndex].unit_price) * Number(value) * Number(companyTax)) / 100;
          newlineItemStates[rowIndex].total = (Number(newlineItemStates[rowIndex].unit_price) * Number(newlineItemStates[rowIndex].qty)) + (Number(newlineItemStates[rowIndex].unit_price) * Number(newlineItemStates[rowIndex].qty) * Number(companyTax) / 100);

          if (newProductLineItem.length == 1 && newProductLineItem[0].qty == 0) {
            var sum = 0;
            for (let i = 0; i < newlineItemStates.length; i++) {
              sum = sum + Number(newlineItemStates[i].total);
              if (newlineItemStates.length - 1 == i) {
                if (discount != "no" && discountValue) {
                  if (discount == "percentage") {
                    sum = sum - (sum * Number(discountValue)) / 100;
                  } else {
                    sum = sum - discountValue
                  }
                }
                setInvoiceTotal(sum)
              }
            }
          } else {
            var sum = 0;
            for (let i = 0; i < newlineItemStates.length; i++) {
              sum = sum + Number(newlineItemStates[i].total);
              if (newlineItemStates.length - 1 == i) {
                for (let x = 0; x < newProductLineItem.length; x++) {
                  sum = sum + Number(newProductLineItem[x].total);
                  if (newProductLineItem.length - 1 == x) {
                    if (discount != "no" && discountValue) {
                      if (discount == "percentage") {
                        sum = sum - (sum * Number(discountValue)) / 100;
                      } else {
                        sum = sum - discountValue
                      }
                    }
                    setInvoiceTotal(sum)
                  }
                }
              }
            }
          }
        }
        if (newlineItemStates[rowIndex].taxable == false && tax) {
          newlineItemStates[rowIndex].amount = 0;
          newlineItemStates[rowIndex].total = (Number(newlineItemStates[rowIndex].unit_price) * Number(newlineItemStates[rowIndex].qty));
          if (newProductLineItem.length == 1 && newProductLineItem[0].qty == 0) {
            var sum = 0;
            for (let i = 0; i < newlineItemStates.length; i++) {
              sum = sum + Number(newlineItemStates[i].total);
              if (newlineItemStates.length - 1 == i) {
                setInvoiceTotal(sum)
              }
            }
          } else {
            var sum = 0;
            for (let i = 0; i < newlineItemStates.length; i++) {
              sum = sum + Number(newlineItemStates[i].total);
              if (newlineItemStates.length - 1 == i) {
                for (let x = 0; x < newProductLineItem.length; x++) {
                  sum = sum + Number(newProductLineItem[x].total);
                  if (newProductLineItem.length - 1 == x) {
                    setInvoiceTotal(sum)
                  }
                }
              }
            }
          }
        }
        if (newProductLineItem.length == 1 && newProductLineItem[0].qty == 0) {
          var sum = 0;
          for (let i = 0; i < newlineItemStates.length; i++) {
            sum = sum + (Number(newlineItemStates[i].qty) * Number(newlineItemStates[i].unit_price));
            if (newlineItemStates.length - 1 == i) {
              setSubTotal(sum)
            }
          }
        } else {
          var sum = 0;
          for (let i = 0; i < newlineItemStates.length; i++) {
            sum = sum + (Number(newlineItemStates[i].qty) * Number(newlineItemStates[i].unit_price));
            if (newlineItemStates.length - 1 == i) {
              for (let j = 0; j < newProductLineItem.length; j++) {
                sum = sum + (Number(newProductLineItem[j].qty) * Number(newProductLineItem[j].unit_price));
                if (newProductLineItem.length - 1 == j) {
                  setSubTotal(sum)
                }
              }
            }
          }
        }
        setProductLineItem(newlineItemStates);
      }
    } catch (error) {
    }
  }

  const handleButton = (type, i) => {
    console.log(type, "type");
    if (type === "+") {
      setProductLineItem([...productLineItem, {
        unique: productLineItem.length,
        external_id: "",
        description: "",
        name: "",
        unit_price: 0,
        qty: 0,
        amount: 0
      }]);
    }
    if (type === "-") {

      if (productLineItem.length > 1) {
        var value = productLineItem.filter((e, ind) => e.unique !== i);
        setProductLineItem(value);
      } else {
        console.log("Cannot remove the last item.");
      }
    }
  }

  const handleNewProduct = async (e, rowIndex, type) => {
    try {
      const newlineItemStates = [...newProductLineItem];
      switch (type) {
        case 'name':
          newlineItemStates[rowIndex].name = e;
          setNewProductLineItem(newlineItemStates);
          break;
        case 'desc':
          newlineItemStates[rowIndex].description = e;
          setNewProductLineItem(newlineItemStates);
          break;
        case 'sku':
          newlineItemStates[rowIndex].sku = e;
          setNewProductLineItem(newlineItemStates);
          break;
        case 'unit':
          newlineItemStates[rowIndex].unit_price = Number(e);
          setNewProductLineItem(newlineItemStates);
          break;
        case 'quantity':
          newlineItemStates[rowIndex].qty = Number(e);
          setNewProductLineItem(newlineItemStates);
          var sum = 0;
          for (let i = 0; i < newlineItemStates.length; i++) {
            var sum = sum + (Number(newlineItemStates[i].qty) * Number(newlineItemStates[i].unit_price));
            if (newlineItemStates.length - 1 == i) {
              for (let z = 0; z < productLineItem.length; z++) {
                sum = sum + (Number(productLineItem[z].qty) * Number(productLineItem[z].unit_price))
              }
              // setSubTotal(sum)
              setSubTotal(isNaN(sum) ? 0 : sum)
            }
          }
          break;
        case 'taxable':
          if (e == "Select") {
            e = false
          }
          newlineItemStates[rowIndex].taxable = (e == 'true' ? true : false);
          newlineItemStates[rowIndex].amount = newlineItemStates[rowIndex].taxable === true ? (Number(newlineItemStates[rowIndex].unit_price) * Number(companyTax) * Number(newlineItemStates[rowIndex].qty)) / 100 : 0;
          // console.log(companyTax, "companyTax");
          // console.log(newlineItemStates[rowIndex].qty, "newlineItemStates[rowIndex].qty");
          // console.log(newlineItemStates[rowIndex].unit_price, "newlineItemStates[rowIndex].unit_price");
          // console.log(newlineItemStates[rowIndex].amount, "newlineItemStates[rowIndex].amount");
          newlineItemStates[rowIndex].total = newlineItemStates[rowIndex].taxable == true ? (Number(newlineItemStates[rowIndex].unit_price) * Number(newlineItemStates[rowIndex].qty)) + (Number(newlineItemStates[rowIndex].unit_price) * Number(newlineItemStates[rowIndex].qty) * Number(companyTax) / 100) : (Number(newlineItemStates[rowIndex].unit_price) * Number(newlineItemStates[rowIndex].qty))
          setNewProductLineItem(newlineItemStates);
          var sum = 0;
          for (let j = 0; j < newlineItemStates.length; j++) {
            sum = sum + Number(newlineItemStates[j].total);
            if (newlineItemStates.length - 1 == j) {
              if (productLineItem.length == 1 && productLineItem[0].qty == 0) {
                // setInvoiceTotal(sum);
                setInvoiceTotal(isNaN(sum) ? 0 : sum);
              } else {
                for (let k = 0; k < productLineItem.length; k++) {
                  sum = sum + productLineItem[k].total;
                  // setInvoiceTotal(sum);
                  setInvoiceTotal(isNaN(sum) ? 0 : sum);
                }
              }
            }
          }
          break;


        default:
          break;
      }
    } catch (error) {

    }

  }

  const handleButtonNew = (type, i) => {
    if (type === "+") {
      setNewProductLineItem([...newProductLineItem, {
        unique: newProductLineItem.length,
        external_id: "",
        description: "",
        name: "",
        unit_price: 0,
        sku: "",
        qty: 0,
        total: "",
        taxable: true,
        amount: ""
      }]);
    }
    if (type === "-") {
      if (newProductLineItem.length > 1) {
        var value = newProductLineItem.filter((e) => e.unique !== i);
        setNewProductLineItem(value);
      } else {
        console.log("Cannot remove the last item.");
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {
      Description: !Description,
      orderType: !orderType,
      name: !name,
      invTotal: !invTotal && invTotal === 0,
      file: !file,
      frequency: orderType === 'recurringOrder' && !frequency,
      frequencyUnits: orderType === 'recurringOrder' && !frequencyUnits
    };
    setInputErrors(errors);
    const hasErrors = Object.values(errors).some((error) => error);
    if (hasErrors) {
      return;
    }
    let valid = true;
    if (orderType === 'recurringOrder') {
      if (!frequency) {
        setFrequencyError('Please select Frequency');
        valid = false;
      } else {
        setFrequencyError("")
      }
      if (!frequencyUnits) {
        setFrequencyUnitsError('Please enter Frequency Units');
        valid = false;
      } else {
        setFrequencyUnitsError("")
      }
    }

    if (!valid) return;
    setLoader(true)
    let DatFind = auth.getAuth();
    if (DatFind == null) {
      setLoader(false)
      return
    }
    const storedAccessToken = localStorage.getItem('accessToken');
    const retrievedData = JSON.parse(storedAccessToken);

    if (!Description || !name || !invTotal || !orderType) {
      setInputErrors(true)
      setLoader(false)
      return;
    }
    var contactData = uniqueContact.filter((e) => e.contactName.trim() === name.trim());
    try {
      if (file) {
        var data = new FormData();
        data.append("file", file);

        var UploadResponse = await axios.post(`${process.env.REACT_APP_URL}/api/invoice/upload`, data, {
          headers: {
            'authorization': DatFind.data.access_token,
            'x-access-token': retrievedData.accessToken,
            mimeType: "multipart/form-data",
          }
        })
      }
      const getValue = [...(productLineItem.length === 1 && productLineItem[0].qty === 0 ? [] : productLineItem), ...(newProductLineItem.length === 1 && newProductLineItem[0].qty === 0 ? [] : newProductLineItem)];
      const getValueUpdated = getValue.filter((ele) => ele.name !== "Select");

      var payload = {
        description: Description,
        customer_360_id: contactData[0].customer_360_id,
        amount: invTotal,
        id: contactData[0].id,
        site_id: retrievedData.SiteId,
        first_name: contactData[0].firstName,
        last_name: contactData[0].lastName,
        mobile_phone: contactData[0].phone,
        email: Email,
        name: name,
        tax: tax,
        discount: discount,
        discountValue: (discount === "no" ? "" : discountValue),
        discountAmount: Number(disAmount),
        due_date: (expire === undefined || expire == null || expire === '' ? "" : expire),
        customer_memo: memo,
        tippingEnabled: tip,
        file: (file ? UploadResponse.data.url : ''),
        line_items: getValueUpdated,
      };
      if (orderType === 'recurringOrder') {
        payload.orderType = 'recurringOrder';
        payload.frequency = frequency;
        payload.frequencyUnits = frequencyUnits;
        payload.subs_external_id = Math.floor(100000000 + Math.random() * 900000000);
      } else {
        payload.orderType = 'oneTimeOrder';
      }
      const responseData = await axios.post(`${process.env.REACT_APP_URL}/api/create/invoice`, payload, {
        headers: {
          'authorization': DatFind.data.access_token,
          'x-access-token': retrievedData.accessToken,
        },
        timeout: 30000
      });

      if (responseData) {
        if (orderType === 'recurringOrder') {
          var contact = uniqueContact.filter((e) => e.contactName.trim() === name.trim());
          const subscriptionData =
          {
            "external_id": payload.subs_external_id,
            "description": Description,
            "amount": invTotal,
            "frequency": frequency,
            "frequencyUntis": frequencyUnits,
            "customer":
            {
              "external_id": Math.floor(100000000 + Math.random() * 900000000),
              "first_name": contact[0].firstName,
              "last_name": contact[0].lastName,
              "mobile_phone": contact[0].phone,
              "email": Email
            }
          }
          // var addSubscription = await axios.post(`https://stage-api.360fivecheckout.io/api/v3/site/${retrievedData.SiteId}/subscription/addUpdate`, subscriptionData, {
          //   headers: {
          //     'x-access-token': retrievedData.accessToken,
          //   }
          // })
        }
        setDescription("")
        setAmount("")
        setServerError("")
        setErrorColor(false)
        setInputErrors({})
      }
      setLoader(false)
      Navigate("/invoice/list");
    } catch (error) {
      setLoader(false)
      console.error('Error:', error.message);
      setServerError("Server Error")
    }
  }

  const handleDraft = async (e) => {
    e.preventDefault();
    setLoader(true)
    let DatFind = auth.getAuth();
    if (DatFind == null) {
      setLoader(false)
      return
    }
    const storedAccessToken = localStorage.getItem('accessToken');
    const retrievedData = JSON.parse(storedAccessToken);
    if (!Description || !Amount || !name) {
      setLoader(false)
      setErrorColor(true)
      return;
    }
    var contactData = uniqueContact.filter((e) => e.contactName.trim() === name.trim());
    try {
      if (file) {
        var data = new FormData();
        data.append("file", file);

        var UploadResponse = await axios.post(`${process.env.REACT_APP_URL}/api/invoice/upload`, data, {
          headers: {
            'authorization': DatFind.data.access_token,
            'x-access-token': retrievedData.accessToken,
            mimeType: "multipart/form-data",
          }
        })
      }

      const getValue = [...(productLineItem.length === 1 && productLineItem[0].qty === 0 ? [] : productLineItem), ...(newProductLineItem.length == 1 && newProductLineItem[0].qty === 0 ? [] : newProductLineItem)];
      const getValueUpdated = getValue.filter((ele) => ele.name !== "Select");

      var payload = {
        description: Description,
        customer_360_id: contactData[0].customer_360_id,
        external_id: (location && location.state && location.state.invoice && location.state.invoice.invoice_id ? location.state.invoice.invoice_id : ""),
        amount: invTotal,
        id: contactData[0].id,
        site_id: retrievedData.SiteId,
        first_name: contactData[0].firstName,
        last_name: contactData[0].lastName,
        mobile_phone: contactData[0].phone,
        email: Email,
        name: name,
        tax: tax,
        discount: discount,
        discountValue: (discount === "no" ? "" : discountValue),
        discountAmount: Number(disAmount),
        due_date: (expire === undefined || expire == null || expire === '' ? "" : expire),
        customer_memo: memo,
        tippingEnabled: tip,
        file: (file ? UploadResponse.data.url : ''),
        line_items: getValueUpdated

      };
      if (orderType === 'recurringOrder') {
        payload.orderType = 'recurringOrder';
        payload.frequency = frequency;
        payload.frequencyUnits = frequencyUnits;
      } else {
        payload.orderType = 'oneTimeOrder';
      }
      const responseData = await axios.post(`${process.env.REACT_APP_URL}/api/draft/invoice`, payload, {
        headers: {
          'authorization': DatFind.data.access_token,
          'x-access-token': retrievedData.accessToken,
        }
      });
      if (responseData) {
        setDescription("")
        setAmount("")
        setServerError("")
        setErrorColor(false)
        setInputErrors({})
      }
      setLoader(false)
      Navigate("/invoice/list");
    } catch (error) {
      setLoader(false)
      console.error('Error:', error.message);
      setServerError("Server Error")
    }
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    const errors = {
      Description: !Description,
      orderType: !orderType,
      name: !name,
      invTotal: !invTotal,
      // file: !file,
      frequency: orderType === 'recurringOrder' && !frequency,
      frequencyUnits: orderType === 'recurringOrder' && !frequencyUnits
    };
    setInputErrors(errors);
    const hasErrors = Object.values(errors).some((error) => error);
    if (hasErrors) {
      return;
    }


    setLoader(true)
    let DatFind = auth.getAuth();
    if (DatFind == null) {
      setLoader(false)
      return
    }
    const storedAccessToken = localStorage.getItem('accessToken');
    const retrievedData = JSON.parse(storedAccessToken);
    if (!Description || !Amount || !name) {
      setErrorColor(true)
      setLoader(false)
      return;
    }
    var contactData = uniqueContact.filter((e) => e.contactName.trim() === name.trim());
    try {
      if (file) {
        var data = new FormData();
        data.append("file", file);

        var UploadResponse = await axios.post(`${process.env.REACT_APP_URL}/api/invoice/upload`, data, {
          headers: {
            'authorization': DatFind.data.access_token,
            'x-access-token': retrievedData.accessToken,
            mimeType: "multipart/form-data",
          }
        })
      }

      const getValue = [...(productLineItem.length === 1 && productLineItem[0].qty === 0 ? [] : productLineItem), ...(newProductLineItem.length === 1 && newProductLineItem[0].qty === 0 ? [] : newProductLineItem)];
      const getValueUpdated = getValue.filter((ele) => ele.name !== "Select");

      var payload = {
        description: Description,
        customer_360_id: contactData[0].customer_360_id,
        external_id: location.state.invoice.invoice_id,
        invoice_number: location.state.invoice.invoice_number,
        amount: invTotal,
        id: contactData[0].id,
        site_id: retrievedData.SiteId,
        first_name: contactData[0].firstName,
        last_name: contactData[0].lastName,
        mobile_phone: contactData[0].phone,
        email: Email,
        name: name,
        tax: tax,
        discount: discount,
        discountValue: (discount == "no" ? "" : discountValue),
        discountAmount: Number(disAmount),
        due_date: (expire == undefined || expire == null || expire == '' ? "" : expire),
        customer_memo: memo,
        tippingEnabled: tip,
        file: (file ? UploadResponse.data.url : ''),
        line_items: getValueUpdated
      };
      if (orderType === 'recurringOrder') {
        payload.orderType = 'recurringOrder';
        payload.frequency = frequency;
        payload.frequencyUnits = frequencyUnits;
        payload.subs_external_id = subs_external_id;
      } else {
        payload.orderType = 'oneTimeOrder';
      }

      const responseData = await axios.put(`${process.env.REACT_APP_URL}/api/invoice`, payload, {
        headers: {
          'authorization': DatFind.data.access_token,
          'x-access-token': retrievedData.accessToken,
        }
      });
      if (responseData) {

        if (orderType === 'recurringOrder') {
          var contact = uniqueContact.filter((e) => e.contactName.trim() === name.trim());
          const subscriptionData =
          {
            "external_id": location.state.invoice.subs_external_id,
            "description": Description,
            "amount": invTotal,
            "frequency": frequency,
            "frequencyUntis": frequencyUnits,
            "customer":
            {
              "external_id": Math.floor(100000000 + Math.random() * 900000000),
              "first_name": contact[0].firstName,
              "last_name": contact[0].lastName,
              "mobile_phone": contact[0].phone,
              "email": Email
            }
          }

          // var addSubscription = await axios.post(`https://stage-api.360fivecheckout.io/api/v3/site/${retrievedData.SiteId}/subscription/addUpdate`, subscriptionData, {
          //   headers: {
          //     'x-access-token': retrievedData.accessToken,
          //   }
          // })
        }
        setDescription("")
        setAmount("")
        setServerError("")
        setErrorColor(false)
        setInputErrors({})

      }
      setLoader(false)
      Navigate("/invoice/list");
    } catch (error) {
      setLoader(false)
      console.error('Error:', error.message);
      setServerError("Server Error")
    }
  }

  return (
    <div>
      <section>
        <nav>
          <div className="menu_bar_btn">
            <span className="material-symbols-outlined" id="menu_btn">
              menu
            </span>
          </div>
        </nav>
      </section>
      <section>
        <div className="PRODUCT_PAGE_SECTION">
          <div className="row">
            <Sidebar />

            <div className="col-lg-12 col-xxl-10 col-xl-9">
              <div className="right_side_content" style={{ position: 'relative' }}>
                {loader ? <Loader /> : <></>}
                <div className="store_data_table " id="PURCHASE_TABLE">
                  <div className="row">
                    <div className="main-title">
                      <h2>Create Invoice</h2>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="Create_Invoice_content">
                        <form >
                          <div className="d-flex">
                            <div className="mb-3">
                              <label className="form-label" > Contact </label>
                              <select className={inputErrors.name ? "errorColor form-control" : "form-control"} onChange={(e) => handleInputChange(e, 'name')} disabled={invAction === "edit" || invAction === "add" ? false : true} value={name} >
                                {invAction === "edit" || invAction === "view" ? <option >{name ? name : "Select Contact"} </option> :
                                  <option defaultValue="">{uniqueContact.length !== 0 ? "Select Contact" : "No Contact"}</option>
                                }

                                {/* <option >{name ? name : "Select Contact"} </option> */}
                                {
                                  uniqueContact && uniqueContact.length !== 0 ?
                                    uniqueContact.map((contact, index) => {
                                      return <option defaultValue={contact.id} key={index} >{contact.contactName}</option>
                                    }) : <></>
                                }
                              </select>
                            </div>
                            <div className="mb-3">
                              <label className="form-label" > Email address </label>
                              <input type="email" className="form-control" value={Email} placeholder="Enter Email Address" disabled />
                            </div>
                          </div>

                          <div className="d-flex">
                            <div className="mb-3">
                              <label className="form-label"  > Phone No. </label>
                              <input type="text" className="form-control" value={phone} placeholder="Enter Phone Number" disabled />
                            </div>
                            <div className="mb-3">
                              <label className="form-label" > Order Type </label>
                              <select className={inputErrors.orderType ? "errorColor form-control" : "form-control"} disabled={invAction === "edit" || invAction === "add" ? false : true} onChange={(e) => handleInputChange(e, 'orderType')} value={orderType} >
                                <option defaultValue="">Select Order Type</option>
                                <option value="oneTimeOrder" >One-Time Payment Orders</option>
                                <option value="recurringOrder" >Subscription Orders</option>
                              </select>
                            </div>
                            {showRecurringOptions && (
                              <>
                                <div className="mb-3">
                                  <label className="form-label">Frequency</label>
                                  <select
                                    className={inputErrors.frequency ? "errorColor form-control" : "form-control"}
                                    onChange={(e) => handleInputChange(e, 'frequency')}
                                    value={frequency}
                                    disabled={invAction === "edit" || invAction === "add" ? false : true}
                                  >
                                    <option defaultValue="">Select Frequency</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Bi-Weekly">Bi-Weekly</option>
                                    <option value="Semi-Monthly">Semi-Monthly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Semi-Annually">Semi-Annually</option>
                                    <option value="Yearly">Yearly</option>
                                  </select>
                                  {frequencyError && <div className="text-danger">{frequencyError}</div>}
                                </div>
                                <div className="mb-3">
                                  <label className="form-label">Frequency Units</label>
                                  <input
                                    type="number"
                                    className={inputErrors.frequencyUnits ? "errorColor form-control" : "form-control"}
                                    placeholder="Frequency Units"
                                    onChange={(e) => handleInputChange(e, 'frequencyUnits')}
                                    value={frequencyUnits}
                                    disabled={invAction === "edit" || invAction === "add" ? false : true}
                                  />
                                  {frequencyUnitsError && <div className="text-danger">{frequencyUnitsError}</div>}
                                </div>
                              </>
                            )}
                            <div className="mb-3">

                              <label className="form-label"  > Description </label>
                              <input type="text" className={inputErrors.Description ? "errorColor form-control" : "form-control"} onChange={(e) => handleInputChange(e, 'Description')} value={Description} placeholder="Enter Description" disabled={invAction === "edit" || invAction === "add" ? false : true} />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="inputs_row">
                                <div className="mb-3">
                                  <label >Add New Item</label>
                                  <select className="form-select" onChange={(e) => handleInputChange(e, 'addNewProduct')} value={newProduct} disabled={invAction === "edit" || invAction === "add" ? false : true}>
                                    <option defaultValue="">Select</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                  </select>
                                </div>
                                <div className="mb-3">
                                  <label >Add Existing Item</label>
                                  <select className="form-select" onChange={(e) => handleInputChange(e, 'addProduct')} value={product} disabled={invAction == "edit" || invAction == "add" ? false : true}>
                                    <option defaultValue="">Select</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label"  > Taxable </label>
                                <select className="form-select" onChange={(e) => handleInputChange(e, 'tax')} value={tax} disabled={invAction == "edit" || invAction == "add" ? false : true}>
                                  <option defaultValue="">Select</option>
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {newProduct && newProductLineItem ? newProductLineItem.map((element, index) => (
                            <div className="d-flex" >
                              <div className="productformat" >
                                <div className="row productRow">
                                  <div className="col-4">
                                    <label >Item Name</label>
                                    <input type="text" className={`form-control`} placeholder="Enter Item Name" onChange={(e) => handleNewProduct(e.target.value, index, 'name')} value={element.name} />
                                  </div>
                                  <div className="col-4">
                                    <label className="form-label" > Item Description </label>
                                    <input type="text" className="form-control" placeholder="Enter Item Description" onChange={(e) => handleNewProduct(e.target.value, index, 'desc')} />
                                  </div>
                                  <div className="col-4">
                                    <label >Item Sku</label>
                                    <input type="text" className="form-control" placeholder="Enter Sku" onChange={(e) => handleNewProduct(e.target.value, index, 'sku')} />
                                  </div>
                                  <div className="col-2">
                                    <label className="form-label" > Unit Price </label>
                                    <input type="number" className="form-control" placeholder="Unit ($)" onChange={(e) => handleNewProduct(e.target.value, index, 'unit')} />
                                  </div>
                                  <div className="col-2">
                                    <label className="form-label" > Quantity </label>
                                    <input type="number" className="form-control" placeholder="Enter Quantity" onChange={(e) => handleNewProduct(e.target.value, index, 'quantity')} />
                                  </div>
                                  <div className="col-2">
                                    <label className="form-label"  > Taxable </label>
                                    <select className="form-select" onChange={(e) => handleNewProduct(e.target.value, index, 'taxable')} >
                                      <option defaultValue="">Select</option>
                                      <option value={true} >Yes</option>
                                      <option value={false} >No</option>
                                    </select>
                                  </div>

                                  <div className="col-1">
                                    <label >Subtotal</label>
                                    <input type="number" className="form-control" disabled value={Number(element.unit_price) * Number(element.qty)} />
                                  </div>
                                  <div className="col-1">
                                    <label >Amount</label>
                                    <input type="text" className="form-control" placeholder="Tax (%)" disabled value={element.amount} />
                                  </div>
                                  <div className="col-1">
                                    <label className="form-label" > Total </label>
                                    <input type="number" className="form-control" placeholder="Enter Total" value={element.total} disabled />
                                  </div>

                                  <div className="col-1 AddDeleteIcon" style={invAction === "edit" || invAction === "add" ? {} : { display: 'none' }}>
                                    <span className="material-symbols-outlined addProduct" onClick={(e) => handleButtonNew('+', index)} > add_circle </span>
                                    <span className="material-symbols-outlined addProduct" onClick={(e) => handleButtonNew('-', element.unique)}> cancel </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )) :
                            <></>
                          }

                          {product && productLineItem ? productLineItem.map((element, indexx) => (
                            <div className="d-flex" key={indexx} style={(product ? { display: "flex" } : { display: "none" })}  >
                              <div className="productformat" >
                                <div className="row productRow">
                                  <div className="col-2">
                                    <label >Item List</label>
                                    <select className="form-select" onChange={(e) => getProductData(e.target.value, indexx, 'name')} value={element.name} disabled={invAction === "edit" || invAction === "add" ? false : true}>
                                      <option>Select</option>
                                      {productList && productList.length !== 0 ? productList.map((ele, ind) => {
                                        return <option key={ele.id}> {ele.name}  </option>
                                      }) : <></>}
                                    </select>
                                  </div>
                                  <div className="col-3">
                                    <label className="form-label" > Item Description </label>
                                    <input type="text" className="form-control" placeholder="" value={element.description} disabled />
                                  </div>
                                  <div className="col-1">
                                    <label >Unit Price</label>
                                    <input type="text" className="form-control" placeholder="Enter Unit price" value={`$ ${element.unit_price.toFixed(2)}`} disabled />
                                  </div>
                                  <div className="col-2">
                                    <label className="form-label" > Quantity </label>
                                    <input type="number" className="form-control" placeholder="Enter Quantity" onChange={(e) => getProductData(e.target.value, indexx, 'quantity')} value={element.qty} disabled={invAction == "edit" || invAction == "add" ? false : true} />
                                  </div>
                                  <div className="col-1">
                                    <label >Sub-Total</label>
                                    <input type="text" className="form-control" placeholder="Enter Unit price" value={`$ ${(Number(element.unit_price) * Number(element.qty)).toFixed(2)}`} disabled />
                                  </div>
                                  <div className="col-1">
                                    <label >Tax</label>
                                    <input type="text" className="form-control" placeholder="Tax (%)" value={`$ ${Number(element.amount).toFixed(2)}`} disabled />
                                  </div>
                                  <div className="col-1">
                                    <label className="form-label" > Total </label>
                                    <input type="text" className="form-control" placeholder="Enter Total" value={element.taxable ? `$ ${((Number(element.unit_price) * Number(element.qty)) + (Number(element.unit_price) * Number(element.qty) * Number(companyTax) / 100)).toFixed(2)}` : `$ ${(Number(element.unit_price) * Number(element.qty)).toFixed(2)}`} disabled />
                                  </div>
                                  <div className="col-1 AddDeleteIcon" style={invAction === "edit" || invAction === "add" ? {} : { display: 'none' }}>
                                    <span className="material-symbols-outlined addProduct" onClick={(e) => handleButton('+', indexx)} > add_circle </span>
                                    <span className="material-symbols-outlined addProduct" onClick={(e) => handleButton('-', element.unique)}> cancel </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )) :
                            <></>
                          }

                          <div className="d-flex">
                            {/* <div className="mb-3">
                              <label className="form-label" > Amount  </label>
                              <input type="number" className="form-control" value={Amount} placeholder="Enter Amount" disabled={true} />
                            </div> */}
                            <div className="mb-3">
                              <label className="form-label" >  Due Date </label>
                              <input type="date" className="form-control" onChange={(e) => handleInputChange(e, 'expire')} value={expire} placeholder="Enter Expiration Time" disabled={invAction == "edit" || invAction == "add" ? false : true} />
                            </div>
                            <div className="mb-3">
                              <label className="form-label" > Total  </label>
                              <input type="text" className="form-control" placeholder="Enter Sub-Total" disabled={true} value={'$ ' + (Number(invTotal) + Number(disAmount)).toFixed(2)} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="inputs_row">
                                <div className="mb-3">
                                  <label className="form-label" >  Discount </label>
                                  <select className="form-select" onChange={(e) => handleInputChange(e, 'discount')} value={discount} disabled={invAction == "edit" || invAction == "add" ? false : true}>
                                    <option value="no" >No</option>
                                    <option value="percentage" >%</option>
                                    <option value="dollar" >$</option>
                                  </select>
                                </div>
                                <div className="mb-3" style={discount != "no" ? { display: "block" } : { display: "none" }}>
                                  <label className="form-label"  > Discount Value  </label>
                                  <input type="text" className="form-control" onChange={(e) => handleInputChange(e, 'discountValue')} value={discountValue} placeholder={discount == "percentage" ? "Enter Discount (%)" : "Enter Discount ($)"} disabled={invAction == "edit" || invAction == "add" ? false : true} />
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-3" >
                                <label className="form-label"  > Discount Amount  </label>
                                <input type="text" className="form-control" value={'$ ' + disAmount.toFixed(2)} disabled />
                              </div>
                            </div>
                          </div>

                          <div className="d-flex End_row_invoice" >
                            <div className="mb-3">
                              <label>Attachment</label>

                              {/* <input
                                  type="file"
                                  name="img"
                                  accept="image/*"
                                  className={inputErrors.file ? "errorColor form-control" : "form-control"}
                                  onChange={(e) => handleInputChange(e, 'file')}
                                  disabled={invAction === "edit" || invAction === "add" ? false : true}
                                /> */}
                              {invAction === "view" ? (
                                <img className="inv_img" src={location?.state?.invoice?.attachment} alt="Preview" width="200" />
                              ) : (
                                <div>

                                  <input
                                    type="file"
                                    name="img"
                                    accept="image/*"
                                    className={inputErrors.file ? "errorColor form-control" : "form-control"}
                                    onChange={(e) => handleInputChange(e, 'file')}
                                    disabled={invAction === "view"}
                                  />
                                  {invAction === "edit" && location?.state?.invoice?.attachment && (
                                    <img className="inv_img" src={location?.state?.invoice?.attachment} alt="Preview" width="200" />
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="mb-3" >
                              <label className="form-label"  > Invoice Total  </label>
                              <input type="text" className={inputErrors.invTotal ? "errorColor form-control" : "form-control"} value={'$ ' + invTotal.toFixed(2)} disabled />
                            </div>

                          </div>

                          {invAction == "add" ?
                            <>
                              <div className="mb-3 submit_invoice_section">
                                <button type="submit" className="invoiceSubmit" onClick={handleDraft} style={auth.isConnection() === true ? { display: "inline-block", marginRight: "10px" } : { display: "none" }}>Save Draft</button>
                                <button type="submit" className="invoiceSubmit1" onClick={handleSubmit} style={auth.isConnection() === true ? { display: "inline-block" } : { display: "none" }}>Send</button>
                              </div>
                            </>
                            :
                            invAction == "edit" ?
                              <>
                                <div className="mb-3 submit_invoice_section">
                                  <button type="submit" className="invoiceSubmit" onClick={handleDraft} style={auth.isConnection() == true ? { display: "inline-block", marginRight: "10px" } : { display: "none" }}>Save Draft</button>
                                  <button type="submit" className="invoiceSubmit1" onClick={handleUpdate} style={auth.isConnection() == true ? { display: "inline-block" } : { display: "none" }}>Send</button>
                                </div>
                              </>
                              :
                              <>
                              </>
                          }
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </section >
    </div >
  );
};

export default Create_Invoice;
