import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Siderbar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import auth from '../service/auth';
import axios from 'axios';
import Loader from './loader/Loader';

const CompanySettings = () => {

  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState('');
  const [companyID, setCompanyID] = useState('');
  const [loader, setLoader] = useState(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [tax, setTax] = useState(0);
  const [address, setAddress] = useState('');

  useEffect(() => {
    getCompanyProfile();
  }, []);

  async function getCompanyProfile() {
    try {
      let userData = auth.getAuth();
      if (userData == null) {
        return
      }
      if (userData && userData.data && userData.data.site && userData.data.site.connected_with.length != 0) {
        var ind = await userData.data.site.connected_with.findIndex((e) => e.location_id == userData.data.data.id);
        if (ind > -1) {
          setTax(Number(userData.data.site.connected_with[ind].tax ? userData.data.site.connected_with[ind].tax : 0))
        }
      }
      setCompanyName(userData.data.data.name);
      setCompanyID(userData.data.data.id);
      setPhone(userData.data.data.phone);
      setEmail(userData.data.data.email);
      setAddress(userData.data.data.address ? userData.data.data.address : '');
    } catch (error) {

    }
  }

  async function submit() {
    try {
      setLoader(true)
      let user = auth.getAuth();
      if (user == null) {
        setLoader(false)
        return
      }
      const storedXAccessToken = localStorage.getItem('accessToken');
      const retrieveXAccessToken = JSON.parse(storedXAccessToken);
      var headres_ = {
        headers: {
          'authorization': user.data.access_token,
          'x-access-token': retrieveXAccessToken.accessToken
        }
      }

      var payload = {
        site_id: retrieveXAccessToken.SiteId,
        location_id: user.data.data.id,
        tax: tax
      };
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/company/setting`, payload, headres_);
      setLoader(false)
      if (response.data.status) {
        toast.success(response.data.status);
        var postData = {
          locationAPIKeyToken: user.data.data.apiKey,
          location_id: user.data.data.id,
          site_id: retrieveXAccessToken.SiteId,
          access_token: retrieveXAccessToken.accessToken,
        };
        const responseData = await axios.post(`${process.env.REACT_APP_URL}/api`, postData);
        auth.connectionUser(responseData);
        window.location.reload();
        // navigate("/dashboard");
      }

    } catch (error) {
      setLoader(false)
      if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
        auth.disconnectUser()
      }
    }
  }

  return (
    <>
      <section>
        <nav>
          <div className="menu_bar_btn">
            <span className="material-symbols-outlined" id="menu_btn">
              menu
            </span>
          </div>
        </nav>
      </section>
      <section>
        <div className="PRODUCT_PAGE_SECTION">
          <div className="row">
            <Sidebar />
            <div className="col-lg-12 col-xxl-10 col-xl-9">
              <div className="row">
                {/* <div className="main-title-SETTINGS">
                  <h2>Company Settings</h2>
                </div> */}
              </div>
              <div className="Company_setting_inner">
                {/* <div className="Left_Bar_company">
                  <CompanySettingsNav />
                </div> */}
                <div className="right_side_content">
                  <div className="COMPANY_INNER_SETTINGS">
                    <div className="row">
                      <div className="main-title">
                        <h2>Company Settings</h2>
                      </div>
                    </div>

                    <div className="ROW">

                      <div className="input_form d-flex">
                        <div className="mb-3 w-100">
                          <label className="form-label">
                            Company Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => setCompanyName(e.target.value)}
                            value={companyName}
                            disabled
                          />
                        </div>
                        <div className="mb-3 w-100">
                          <label className="form-label">
                            Company ID *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => setCompanyID(e.target.value)}
                            value={companyID}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="input_form d-flex">
                        <div className="mb-3 w-100 ">
                          <label className="form-label">
                            Phone *{" "}
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            disabled
                          />
                        </div>

                        <div className="mb-3 w-100 ">
                          <label className="form-label">
                            Email *
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="input_form d-flex">
                        <div className="mb-3 w-100 ">
                          <label className="form-label">
                            Tax Rate (%)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => setTax(e.target.value)}
                            placeholder="Enter Tax (%)"
                            value={tax}
                          />

                        </div>
                        {/* <div className="mb-3 w-100 ">
                          <label className="form-label">
                            Sur Charge Text
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => setSurcharge(e.target.value)}
                            value={surCharge}
                          />
                        </div> */}
                      </div>

                      <div className="input_form d-flex">
                        {/* <div className="mb-3 w-100 ">
                          <label className="form-label">
                            Payment Form ID
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => setPayment(e.target.value)}
                            value={payment}
                          />
                        </div> */}

                        {/* <div className="mb-3 w-100 ">
                          <label className="form-label">
                            Theme Direction *
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setTheme(e.target.value)}
                          >
                            <option defaultValue>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div> */}
                      </div>

                      <div className="input_form d-flex xampleInput">
                        <label className="form-label">
                          Address *
                        </label>
                        <textarea
                          name=""
                          id="exampleInputEmail1"
                          aria-label="Default select example"
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                          disabled
                        ></textarea>
                      </div>

                      <button className="filter mt-3" style={auth.isConnection() == true ? { display: "block" } : { display: "none" }} onClick={submit}>
                        <span className="material-symbols-outlined">save</span> Save
                        Settings{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </section>
    </>
  );
};

export default CompanySettings;
