import React, { useState, useEffect } from "react";
import Sidebar from "../Siderbar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import auth from '../service/auth';
import Loader from './loader/Loader';

const Logo_upload = () => {

  const [file, setFile] = useState();
  const [loader, setLoader] = useState(false);

  async function upload() {
    try {
      setLoader(true)
      let DatFind = auth.getAuth();
      if (DatFind == null) {
        setLoader(false)
        return
      }
      const storedAccessToken = localStorage.getItem('accessToken');
      const retrievedData = JSON.parse(storedAccessToken);
      if (file) {
        var data = new FormData();
        data.append("file", file);

        var UploadResponse = await axios.post(`${process.env.REACT_APP_URL}/api/logo/upload`, data, {
          headers: {
            'authorization': DatFind.data.access_token,
            'x-access-token': retrievedData.accessToken,
            mimeType: "multipart/form-data",
          }
        })
        const fileName = document.querySelector('input[type=file]');
        fileName.value = '';
        toast.success("Upload sucessfully.")
      }
      setLoader(false);
    } catch (error) {
      setLoader(false)
      toast.error("Internal server error")
    }

  }

  return (
    <div>
      <section>
        <nav>
          <div className="menu_bar_btn">
            <span className="material-symbols-outlined" id="menu_btn">
              menu
            </span>
          </div>
        </nav>
      </section>
      <section>
        <div className="PRODUCT_PAGE_SECTION">
          <div className="row">
            <Sidebar />
            <div className="col-lg-12 col-xxl-10 col-xl-9">
              <div className="row">
                {/* <div className="main-title-SETTINGS">
                    <h2>Company Settings</h2>
                  </div> */}
              </div>
              <div className="Company_setting_inner">
                {/* <div className="Left_Bar_company">
                    <CompanySettingsNav />
                  </div> */}
                <div className="right_side_content">
                  <div className="COMPANY_INNER_SETTINGS">
                    <div className="row">
                      <div className="main-title">
                        <h2>Upload Logo</h2>
                      </div>
                    </div>
                    {loader ? <Loader /> : <></>}
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input_form d-flex">
                          <div className="mb-3 w-100 ">
                            <label
                              className="form-label"
                            >
                              Upload Iogo
                            </label>

                            <input type="file"

                              name="img"
                              accept="image/*"
                              className="form-control"
                              onChange={(e) => setFile(e.target.files[0])}
                            />

                            <form className="Add_FILE_UPLOAD" style={auth.isConnection() == true ? { display: "block" } : { display: "none" }}>
                              <label onClick={upload} >
                                {" "}
                                <span className="material-symbols-outlined" >
                                  upload
                                </span>{" "}
                                Uplaod
                              </label>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </section>

      <script src="js/main.js"></script>

    </div>
  );
};

export default Logo_upload;
