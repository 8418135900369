import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import auth from "../service/auth";
import { useNavigate, useParams } from "react-router-dom";

const Sidebar = () => {
  const Navigate = useNavigate();

  const [invoiceDD, setInvoiceDD] = useState(false);
  const [productDD, setProductDD] = useState(false);
  const [companySettingDD, setCompanySettingeDD] = useState(false);
  const [generalSettingDD, setGeneralSettingeDD] = useState(false);
  const [apiDD, setApiDD] = useState(false);
  const [activeRoute, setActiveRoute] = useState();
  const [condition, setcondition] = useState();

  useEffect(() => {
    getActiveClass();
    openDropDown(activeRoute);
  }, [activeRoute]);

  async function getActiveClass() {
    var URL = window.location.href;
    if (URL) {
      var ROUTE = URL.split('/')[URL.split('/').length - 1];
      setActiveRoute(ROUTE);
    }
  }

  async function changeValue(invoice, product, comSetting, api, genSettine) {
    setInvoiceDD(invoice);
    setProductDD(product);
    setCompanySettingeDD(comSetting);
    setApiDD(api);
    setGeneralSettingeDD(genSettine);
  }

  async function openDropDown(activeRoute) {
    switch (activeRoute) {
      case 'dashboard':
        changeValue(true, false, false, false, false);
        break;
      case 'create':
        changeValue(true, false, false, false, false);
        break;
      case 'list':
        changeValue(true, false, false, false, false);
        break;
      case 'product':
        changeValue(false, true, false, false, false);
        break;
      case 'create_':
        changeValue(false, true, false, false, false);
        break;
      case 'list_':
        changeValue(false, true, false, false, false);
        break;
      case 'setting':
        changeValue(false, false, true, false, false);
        break;
      case 'setting_':
        changeValue(false, false, true, false, true);
        break;
      case 'upload':
        changeValue(false, false, true, false, false);
        break;
      case ':id':
        changeValue(false, false, true, false, false);
        break;
      case 'taxes':
        changeValue(false, false, true, false, false);
        break;
    }
  }

  useEffect(() => {
    let Connectiondata = auth.isConnection();
    setcondition(Connectiondata)
  }, []);

  return (
    <div className="col-lg-3 col-xxl-2 col-xl-3">
      <div className="left_side_tabs">
        <span className="material-symbols-outlined" id="close_top_btn">
          close
        </span>
        <div className="side_links">
          <ul>
            <li>
              {" "}
              <Link to="/dashboard" className={invoiceDD && activeRoute == 'dashboard' ? "active " : ""} >
                {" "}
                <span className="material-symbols-outlined">article</span>
                Invoices
                <span
                  className={invoiceDD ? "material-symbols-outlined arrowDown" : "material-symbols-outlined arrowStraight"}
                  id="Arrow_r"
                >
                  {" "}
                  chevron_right{" "}
                </span>{" "}
              </Link>
              <ul className={invoiceDD ? "First_drop_ul" : "hideDropDown"}>
                <li>
                  <Link to="/invoice/create" className={activeRoute == 'create' ? "active dropdown-item" : "dropdown-item"}>
                    {" "}
                    Create Invoice
                  </Link>
                </li>
                <li>
                  <Link to="/invoice/list" className={activeRoute == 'list' ? "active dropdown-item" : "dropdown-item"}>
                    Invoice List
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              {" "}
              <Link to="/product" className={productDD && activeRoute == 'product' ? "active " : ""} >
                <span className="material-symbols-outlined">shopping_cart</span>{" "}
                Item
                <span
                  className={productDD ? "material-symbols-outlined arrowDown" : "material-symbols-outlined arrowStraight"}
                  id="Arrow_r"
                >
                  {" "}
                  chevron_right{" "}
                </span>{" "}
              </Link>
              <ul className={productDD ? "First_drop_ul" : "hideDropDown"} >
                <li>
                  <Link to="/product/create_" className={activeRoute == 'create_' ? "active dropdown-item" : "dropdown-item"}>
                    {" "}
                    Create Item
                  </Link>
                </li>
                <li>
                  <Link to="/product/list_" className={activeRoute == 'list_' ? "active dropdown-item" : "dropdown-item"}>
                  Item List
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              {" "}
              <Link to="/contact" className={activeRoute == 'contact' ? "active" : ""} >
                <span className="material-symbols-outlined">Contacts</span>{" "}
                Contacts

              </Link>
            </li>
            <li>
              {" "}
              <Link to="/order" className={activeRoute == 'order' ? "active" : ""} >
                <span className="material-symbols-outlined">shopping_cart</span>{" "}
                Order
              </Link>
            </li>
            <li>
              <Link to="/payment/form" className={activeRoute == 'form' ? "active" : ""}>
                {" "}
                <span className="material-symbols-outlined">article</span>
                Payments

              </Link>
            </li>

            <li>
              {" "}
              <Link to="/company/setting" className={companySettingDD && activeRoute == 'setting' ? "active " : ""}>
                {" "}
                <span className="material-symbols-outlined">settings</span>
                Company Settings
                <span
                  className={companySettingDD ? "material-symbols-outlined arrowDown" : "material-symbols-outlined arrowStraight"}
                  id="Arrow_r"
                >
                  chevron_right
                </span>{" "}
              </Link>
              <ul className={companySettingDD ? "First_drop_ul" : "hideDropDown"} >
                <li>
                  {
                    condition ? <Link to="/dashboard" className={companySettingDD && activeRoute == '/dashboard' ? "active" : ""}>
                      {" "}
                      <span className="material-symbols-outlined">
                        credit_card
                      </span>
                      Connect Gateway

                    </Link> :
                      <Link to="/:id" className={companySettingDD && activeRoute == ':id' ? "active" : ""}>
                        {" "}
                        <span className="material-symbols-outlined">
                          credit_card
                        </span>
                        Connect Gateway

                      </Link>
                  }
                </li>
                <li>
                  <Link to="/logo/upload" className={companySettingDD && activeRoute == 'upload' ? "active" : ""}>
                    {" "}
                    <span className="material-symbols-outlined">imagesmode</span>
                    Logo

                  </Link>
                </li>

              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
