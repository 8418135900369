import React, { useState, useEffect } from "react";
import Sidebar from "../Siderbar/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import auth from '../service/auth';
import axios from 'axios';
import moment from 'moment';
import Loader from './loader/Loader';
import Contact from '../service/contact';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import Modal from "react-modal";

const Invoice = () => {
  const navigate = useNavigate();

  const [invoices, setInvoices] = useState([]);
  const [rawInvoices, setRawInvoices] = useState([]);
  const [filter, setFilter] = useState([]);
  const [invoiceAmount, setInvoiceAmount] = useState(0.00);
  const [loader, setLoader] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  async function disconnect() {
    try {
      auth.logout();
      // navigate("/:id");
      var locationID = localStorage.getItem('location_id');
      navigate("/ghlAauthentication/:" + locationID);
    } catch (error) {
      console.log('error', error);
    }
  }

  async function getInvoiceList() {
    try {
      setLoader(true)
      let user = auth.getAuth();
      if (user == null) {
        setLoader(false)
        return
      }
      var headres_ = {
        headers: {
          'authorization': user.data.access_token,
          locationapikeytoken: user.data.data.apiKey
        }
      }
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/invoices/customer`, headres_);
      setRawInvoices(response.data.data)
      var sentInvoices = response.data.data.filter((inv, ind) => { return inv.status === "sent" })
      setInvoices(sentInvoices);
      setFilter(sentInvoices);
      var sum = 0;
      for (let i = 0; i < sentInvoices.length; i++) {
        sum = sum + Number(sentInvoices[i].amount)
      }
      setInvoiceAmount(sum)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
        auth.disconnectUser()
      }
      console.log('error', error);
    }
  }

  async function handleAction(type, data) {
    if (type === "customer") {
      window.open(data.url);
    } else {
      navigate("/invoice/create", { state: { invoice: data, type: type } })
    }
  }

  function redirectToExternalUrl() {
    window.location.replace('https://spa.secureapp.io');
    return null;
  }

  async function filterData(event) {
    if (invoices && invoices.length !== 0) {
      var result = invoices.filter((e) => String(e.invoice_number).includes(event.target.value));
      setFilter(result);
    }
  }

  const handleCancel = () => {
    setIsModalOpen(true);
  }

  useEffect(() => {
    getInvoiceList();
  }, [])

  function getInvoiceCount(type) {
    try {
      if (type === "sent") {
        var sentInvoices = rawInvoices.filter((inv, ind) => { return inv.status === "sent" })
      }
      if (type === "draft") {
        var sentInvoices = rawInvoices.filter((inv, ind) => { return inv.status === "draft" })
      }
      if (type === "paid") {
        var sentInvoices = rawInvoices.filter((inv, ind) => { return inv.status === "paid" })
      }
      if (type === "cancelled") {
        var sentInvoices = rawInvoices.filter((inv, ind) => { return inv.status === "cancelled" })
      }
      var sum = 0;
      for (let i = 0; i < sentInvoices.length; i++) {
        sum = sum + Number(sentInvoices[i].amount)
      }
      return { invoiceLength: sentInvoices.length, invoiceAmount: sum }
    } catch (error) {

    }
  }

  return (
    <div>
      <section>
        <nav>
          <div className="menu_bar_btn">
            <span className="material-symbols-outlined" id="menu_btn">
              menu
            </span>
          </div>
        </nav>
      </section>

      <section>
        <div className="PRODUCT_PAGE_SECTION">
          <div className="row">
            <Sidebar />
            <div className="col-lg-12 col-xxl-10 col-xl-9">
              <div className="right_side_content">
                <div className="store_data_table " id="INVOICE_TABLE">
                  <div className="row">
                    <div className="main-title">
                      <h2>
                        Invoices <br />
                        <p
                          style={{
                            fontSize: '12px',
                            fontWeight: '400',
                            marginTop: '5px',
                            color: '#757575',
                          }}
                        >
                          Create and manage all invoices generated for your
                          business
                        </p>
                      </h2>
                      <div className="method">
                        <button onClick={openModal} className="filter" style={auth.isConnection() === true ? { display: "block", marginRight: '10px' } : { display: "none" }}>Disconnect</button>
                        <button className="filter" onClick={redirectToExternalUrl} >Apply Now</button>
                      </div>
                    </div>
                  </div>

                  <div className="Gateway">
                    <div className="invoice_draft_box">
                      <p>{getInvoiceCount("draft").invoiceLength} Invoice(s) in Draft</p>
                      <h2>${getInvoiceCount("draft").invoiceAmount.toFixed(2)}</h2>
                    </div>
                    <div className="invoice_draft_box">
                      <p>{getInvoiceCount("sent").invoiceLength} Invoice(s) in Outstanding</p>
                      <h2>${getInvoiceCount("sent").invoiceAmount.toFixed(2)}</h2>
                    </div>
                    <div className="invoice_draft_box">
                      <p>{getInvoiceCount("paid").invoiceLength} Invoice(s) in Paid</p>
                      <h2>${getInvoiceCount("paid").invoiceAmount.toFixed(2)}</h2>
                    </div>
                    <div className="invoice_draft_box">
                      <p>{getInvoiceCount("cancelled").invoiceLength} Invoice(s) in Cancelled</p>
                      <h2>${getInvoiceCount("cancelled").invoiceAmount.toFixed(2)}</h2>
                    </div>
                  </div>
                  <div className="top_header_btns">
                    <input
                      type="search"
                      className="form-control form-control-sm search_invoices"
                      placeholder="Search Invoice Number*"
                      aria-controls="example"
                      onChange={e => filterData(e)}
                    />

                    <Link to="/invoice/create" className="AddNewElement">
                      <button className="filter">
                        <span className="material-symbols-outlined"> add  </span>{" "} Add New Invoice</button>
                    </Link>
                  </div>


                  <TableContainer >
                    <Table id="invoiceTable" className="table table-striped table-bordered " style={{ width: '100%' }}>
                      <TableHead className="tableHeader">
                        <TableRow>
                          <TableCell>Invoice Number</TableCell>
                          <TableCell>Client</TableCell>
                          <TableCell>Invoice Date</TableCell>
                          <TableCell>Due Date</TableCell>
                          <TableCell>Grand Total</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!loader && invoices && invoices.length !== 0 ? filter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          ?.map((invoice, index) => {
                            return (
                              <TableRow key={index}>
                                {/* <TableCell> </TableCell> */}
                                <TableCell>{invoice.invoice_number}</TableCell>
                                <TableCell>{Contact.showContactList(invoice.user)}</TableCell>
                                <TableCell>{moment(invoice.createdAt).format('MM/DD/YYYY')}</TableCell>
                                <TableCell>{invoice.due_date ? moment(invoice.due_date).format('MM/DD/YYYY') : ""}</TableCell>
                                <TableCell>{"$" + (String(invoice.amount).includes(".") ? (Math.round(invoice.amount * 100) / 100).toFixed(2) : (invoice.amount == null ? 0 : invoice.amount) + ".00")}</TableCell>
                                <TableCell>{invoice.status == "sent" ?
                                  <div className="paid Sent">
                                    <p>{invoice.status}</p>
                                  </div> :
                                  (invoice.status == "draft" ?
                                    <div className="paid Saved">
                                      <p>{invoice.status}</p>
                                    </div>
                                    :
                                    (invoice.status == "paid" ?
                                      <div className="paid Paid">
                                        <p>{invoice.status}</p>
                                      </div>
                                      :
                                      <div className="paid Cancelled">
                                        <p>{invoice.status}</p>
                                      </div>
                                    )
                                  )
                                }</TableCell>
                                <TableCell>
                                  <div className="action-container">
                                    <button className="view-button" onClick={(e) => handleAction("view", invoice)}>View</button>
                                    <button className="edit-button" onClick={(e) => handleAction("edit", invoice)}>Edit</button>
                                    <button className="edit-button" onClick={(e) => handleAction("customer", invoice)}>Open as Customer</button>
                                    {/* <button className=" btn btn-danger" onClick={handleCancel}>Cancel</button> */}
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })
                          : (invoices.length == 0 ? <></> : <><Loader /></>)
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={invoices.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div >
      </section >
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="custom-modal "
        overlayClassName="custom-modal-overlay"
      >
        <div className='customer-card cancelModal'>
          <div className='cus_inner_card_body '>

            <div className='row'>

              <svg fill="#000000" width="64px" height="64px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" className="No_svg">
                <path d="M213.333 960c0-167.36 56-321.707 149.44-446.4L1406.4 1557.227c-124.693 93.44-279.04 149.44-446.4 149.44-411.627 0-746.667-335.04-746.667-746.667m1493.334 0c0 167.36-56 321.707-149.44 446.4L513.6 362.773c124.693-93.44 279.04-149.44 446.4-149.44 411.627 0 746.667 335.04 746.667 746.667M960 0C429.76 0 0 429.76 0 960s429.76 960 960 960 960-429.76 960-960S1490.24 0 960 0" fill-rule="evenodd" />
              </svg>
              <div className='col-11 modal_head_heading' style={{ marginLeft: '20px' }}>
                Are You Sure ?
              </div>

            </div>
            <div className='row'>
              <div className="modal_body_section method">
                <button className="merchant" onClick={disconnect}>Yes</button>
                <button className="gateway" onClick={closeModal} >No</button>
              </div>

            </div>

          </div>
        </div>
      </Modal>
    </div >
  );
};

export default Invoice;
