import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Siderbar/Sidebar";
import auth from '../service/auth';
import axios from 'axios';
import Loader from './loader/Loader';
import TablePagination from "@mui/material/TablePagination";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const Product_list = () => {
  const navigate = useNavigate();

  const [productList, setProductList] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [loader, setLoader] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getProductList();
  }, []);

  async function getProductList() {
    try {
      setLoader(true)
      let user = auth.getAuth();
      if (user == null) {
        setLoader(false)
        return
      }
      var headres_ = {
        headers: {
          'authorization': user.data.access_token,
        }
      }
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/products`, headres_);
      setProductList(response.data.data)
      setSearchFilter(response.data.data)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
        auth.disconnectUser()
      }
      console.log('error', error);
    }
  }

  async function addProduct() {
    navigate('/product/create_', { state: { product: {}, type: "add" } })
  }

  async function handleAction(type, data) {
    navigate("/product/create_", { state: { product: data, type: type } })
  }

  async function filter(event) {
    if (event == "") {
      setSearchFilter(productList);
      return
    }
    if (productList && productList.length != 0) {
      var result = productList.filter((e) => String(e.name).toLowerCase().includes(event));
      setSearchFilter(result);
    }
  }

  return (
    <div>
      <section>
        <nav>
          <div className="menu_bar_btn">
            <span className="material-symbols-outlined" id="menu_btn">
              menu
            </span>
          </div>
        </nav>
      </section>

      <section>
        <div className="PRODUCT_PAGE_SECTION">
          <div className="row">
            <Sidebar />

            <div className="col-lg-12 col-xxl-10 col-xl-9">
              <div className="right_side_content">
                <div className="store_data_table">
                  <div className="row">
                    <div className="main-title">
                      <h2>Item List</h2>
                      <button className="filter" onClick={addProduct}>
                        <span className="material-symbols-outlined"> add </span>{" "}
                        Add New{" "} Item
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="main-title">
                      <div className="searchfilter">
                        <label className="searchLabel">  {" "} Search:   </label>
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Enter Name"
                          aria-controls="example"
                          onChange={e => filter(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <TableContainer >
                    <Table id="example" className="table table-striped table-bordered" style={{ width: "100%" }}>
                      <TableHead className="tableHeader">
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Taxable</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!loader && productList && productList.length != 0 ? searchFilter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          ?.map((product, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{product.name} </TableCell>
                                <TableCell > {product.description} </TableCell>
                                <TableCell>{"$" + (String(product.unit_price).includes(".") ? product.unit_price : product.unit_price + ".00")}</TableCell>
                                <TableCell > {product.taxable == true ? "Yes" : "No" } </TableCell>
                                <TableCell>
                                  <div className="action-container">
                                    <button className="view-button" onClick={(e) => handleAction("view", product)}>View</button>
                                    <button className="edit-button" onClick={(e) => handleAction("edit", product)}>Edit</button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })
                          : (productList.length == 0 ? <></> : <><Loader /></>)
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={productList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Product_list;
