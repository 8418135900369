exports.contactList = (data) => {
    try {
        if (data.length !== 0) {

            for (let i = 0; i < data.length; i++) {
                var data_firstName = (data[i].firstName !== "" && data[i].firstName != null? data[i].firstName.charAt(0).toUpperCase().trim() + data[i].firstName.slice(1) : "");
                var data_lastName = (data[i].lastName !== "" && data[i].lastName != null ? data[i].lastName.charAt(0).toUpperCase().trim() + data[i].lastName.slice(1) : "");
                data[i].contactName = data_firstName + " " + data_lastName
            }
        }
        return data
    } catch (error) {
        console.log('Service => contact error', error);
    }
}

exports.showContactList = (data) => {
    try {
        var data_firstName = (data.firstName !== "" && data.firstName != null ? data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1) : "");
        var data_lastName = (data.lastName !== ""  && data.lastName != null ? data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1) : "");
        return data_firstName + " " + data_lastName
    } catch (error) {
        console.log('Service => show contact error', error);
    }
}

exports.showContactFirstName = (data) => {
    try {
        var data_firstName = (data.firstName !== "" && data.firstName != null ? data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1) : "");
        return data_firstName
    } catch (error) {
        console.log('Service => show contact error', error);
    }
}

exports.showContactLastName = (data) => {
    try {
        var data_lastName = (data.lastName !== "" && data.lastName != null  ? data.lastName.charAt(0).toUpperCase().trim() + data.lastName.slice(1) : "").trim();
        return data_lastName
    } catch (error) {
        console.log('Service => show contact error', error);
    }
}