import React from 'react';
import "./Loader.css"

function Loader() {
  return (
    <div className="bouncing-loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loader;