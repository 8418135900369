import React, { useState, useEffect } from "react";
import Sidebar from "../Siderbar/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import auth from '../service/auth';
import CreditCard from '../assets/credit-card (1).png';
import axios from 'axios';
import moment from 'moment';
import Loader from './loader/Loader';
import TablePagination from "@mui/material/TablePagination";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const Payment_form = () => {
    const navigate = useNavigate();

    const [invoices, setInvoices] = useState([]);
    const [loader, setLoader] = useState(false);
    const [calender, setCalender] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getInvoiceList();
    }, [])

    async function getInvoiceList() {
        try {
            setLoader(true)
            let user = auth.getAuth();
            if (user == null) {
                setLoader(false)
                return
            }
            var headres_ = {
                headers: {
                    'authorization': user.data.access_token
                }
            }
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/paid/invoices`, {}, headres_);
            setInvoices(response.data.data);
            setLoader(false)
        } catch (error) {
            setLoader(false)
            if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
                auth.disconnectUser()
            }
            console.log('error', error);
        }
    }

    async function handleDateFilter(e, type) {
        try {
            setLoader(true)
            setStartDate("");
            setEndDate("");
            let user = auth.getAuth();
            if (user == null) {
                setLoader(false)
                return
            }
            var headres_ = {
                headers: {
                    'authorization': user.data.access_token
                }
            }
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/date`, { previous: type }, headres_);
            setCalender(!calender)
            var paid_ = await response.data.data.filter((element) => element.status == "paid");
            setInvoices(paid_)
            setLoader(false)
        } catch (error) {
            setLoader(false)
            if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
                auth.disconnectUser()
            }
            console.log('error', error);
        }
    }

    async function handleDateRangeFilter(e, type) {
        try {
            let user = auth.getAuth();
            if (user == null) {
                setLoader(false)
                return
            }
            var headres_ = {
                headers: {
                    'authorization': user.data.access_token
                }
            }
            if (type == "start") {
                setStartDate(e.target.value)
                if (endDate) {
                    setLoader(true)
                    const response = await axios.post(`${process.env.REACT_APP_URL}/api/date`, { start_date: e.target.value, end_date: endDate }, headres_);
                    setCalender(!calender)
                    var paid_ = await response.data.data.filter((element) => element.status == "paid");
                    setInvoices(paid_)
                    setLoader(false)
                }

            }
            if (type == "end") {
                setEndDate(e.target.value)
                if (startDate) {
                    setLoader(true)
                    const response = await axios.post(`${process.env.REACT_APP_URL}/api/date`, { start_date: startDate, end_date: e.target.value }, headres_);
                    setCalender(!calender)
                    var paid_ = await response.data.data.filter((element) => element.status == "paid");
                    setInvoices(paid_)
                    setLoader(false)
                }
            }

        } catch (error) {
            setLoader(false)
            if (error && error.response && error.response.data && error.response.data.message && error.response.data.message == "jwt expired") {
                auth.disconnectUser()
            }
            console.log('error', error);
        }
    }

    return (
        <div>
            <section>
                <nav>
                    <div className="menu_bar_btn">
                        <span className="material-symbols-outlined" id="menu_btn">
                            menu
                        </span>

                    </div>
                </nav>
            </section>

            <section>

                <div className="PRODUCT_PAGE_SECTION">
                    <div className="row">
                        <Sidebar />
                        <div className="col-lg-12 col-xxl-10 col-xl-9">
                            <div className="right_side_content">

                                <div className="store_data_table">
                                    <div className="row">
                                        <div className="main-title">

                                            <h2>Payments</h2>
                                        </div>
                                        <div className="row">
                                            <div className="main-title">
                                                <div className="searchfilter searchFilter2">
                                                    <div className="serachLabel_payment">
                                                        <span class="material-symbols-outlined">
                                                            search
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="search"
                                                        className="form-control form-control-sm order_payment"
                                                        placeholder="Search Payments"
                                                        aria-controls="example"
                                                    //   onChange={e => filter(e.target.value)}
                                                    />

                                                    <div className="input_img_div" onClick={(e) => setCalender(!calender)}>
                                                        <span className="material-symbols-outlined">
                                                            calendar_month
                                                        </span>
                                                    </div>

                                                    <div className="clender_section" style={calender ? { display: "block" } : { display: "none" }}>
                                                        <div className="inner_div_clender">
                                                            <div className="days_section">
                                                                <button type="button" onClick={(e) => handleDateFilter(e, "today")} value="today">Today</button>
                                                                <button type="button" onClick={(e) => handleDateFilter(e, "7-Days")} value="7-Days">7 Days</button>
                                                                <button type="button" onClick={(e) => handleDateFilter(e, "This-Week")} value="This-Week">This Week</button>
                                                                <button type="button" onClick={(e) => handleDateFilter(e, "Last-Year")} value="Last-Year">Last Year</button>
                                                                <button type="button" onClick={(e) => handleDateFilter(e, "All-Time")} value="All-Time">All Time</button>

                                                            </div>
                                                            <div className="inputs_div">
                                                                <div className="clender_input">
                                                                    <label  >
                                                                        Start_Date
                                                                    </label>
                                                                    <input type="date" onChange={(e) => handleDateRangeFilter(e, "start")} ></input>
                                                                </div>
                                                                <div className="clender_input">
                                                                    <label  >
                                                                        End_Date
                                                                    </label>
                                                                    <input type="date" onChange={(e) => handleDateRangeFilter(e, "end")} ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <TableContainer >
                                        <Table id="example" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                            <TableHead className="tableHeader">
                                                <TableRow>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Amount</TableCell>
                                                    <TableCell>Paid By</TableCell>
                                                    <TableCell>Method</TableCell>
                                                    <TableCell>Invoice Number</TableCell>
                                                    <TableCell>Date</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {!loader && invoices && invoices.length != 0 ? invoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    ?.map((invoice, index) => {
                                                        return (
                                                            <TableRow key={invoice.id}>
                                                                {/* <TableCell> </TableCell> */}
                                                                <TableCell>
                                                                    <div className="paid">
                                                                        <p>{invoice.status}</p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>
                                                                    ${(Math.round(invoice.webhook[0].amount * 100) / 100).toFixed(2)}
                                                                </TableCell>
                                                                <TableCell>{invoice.webhook[0].customer_display}</TableCell>
                                                                <TableCell>
                                                                    <div className="card_td"> <img src={CreditCard} width="20px" alt="" />
                                                                        <p>{invoice.webhook[0].display_name}</p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>{invoice.webhook[0].invoice_number}</TableCell>
                                                                <TableCell>{moment(invoice.webhook[0].paid_on).format('MM/DD/YYYY')}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                    : (invoices.length == 0 ? <></> : <><Loader /></>)
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={invoices.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section>


            <div className="add_product_popup">
                <div className="inner_popup">
                    <div className="popup_header">
                        <h4>Add Product</h4>
                        <span className="material-symbols-outlined" id="close">
                            close
                        </span>
                    </div>

                    <div className="popup_body">
                        <div className="first_one_time"> <label  >Product Type <span>*</span></label>
                            <select className="form-select" aria-label="Default select example" id="Product">
                                <option defaultValue>Open this select</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select></div>

                        <div> <label  >Product SKU <span>*</span></label>
                            <input type="text" /></div>

                        <div> <label  >Product Name <span>*</span></label>
                            <input type="text" /></div>


                        <div className="Product_cost">
                            <div>
                                <label  >Product Cost $<span>*</span></label>
                                <input type="text" />
                            </div>

                            <div>
                                <label  >One Time Setup Fee Product Cost</label>
                                <input type="text" />
                            </div>

                        </div>

                    </div>

                    <div className="radio_have">
                        <input type="checkbox" />
                        <p>Have Trial?</p>
                    </div>

                    <div className="editer_sec">
                        <label >Description <span>*</span></label>
                        <textarea id="editor"></textarea>
                        <button type="button" id="Save_changes"><span className="material-symbols-outlined">
                            save
                        </span> Save Changes</button>
                    </div>
                </div>
            </div>





            <script src="js/main.js"></script>

        </div>
    )
}

export default Payment_form
